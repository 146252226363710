import React, { useState ,useEffect} from 'react';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Autocomplete from '@mui/material/Autocomplete';
import {FormControl,
    Select,
    MenuItem,
    InputLabel,
    Button, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, IconButton } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import logo from './logo.png';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { CreateTwoTone } from "@material-ui/icons";
import Message from "./Message";
import Formservice from "./../service/Formservice";
import PGIService from "./../service/PGIService";

function DomaineCompetence() {
    const navigate = useNavigate();
    const location = useLocation();
    // const params = new URLSearchParams(location.search);
    const ref = localStorage.getItem('ref');;
    const [identif,setIdentif]=useState([]);
    const [desc,setDesc]=useState({desc:""});
    const parts = ref.split('_');
    const prefix = parts[0];
    const [message, setMessage] = React.useState({
        open: false,
        color: "green",
        messageInfo: "",
      });
      const { open, color, messageInfo } = message;
    const [form, setForm] = useState({
        domaine: "",
        compt: "",
    });
    const [formation,setFormation]=useState({
      id:"",
      domaine:"",
      theme:"",
      datedebut:"",
      datefin:"",
      fid:"",
      autres:"",
      certificat:"",
      jour:"",
      mois:"",
      annee:"",
      jour1:"",
      mois1:"",
      annee1:""
    });
    const years = [];
    const currentYear = 31;

    for (let year = 1; year <= currentYear; year++) {
        years.push(year.toString());
    }

    const years1 = [];
    const currentYear1 = new Date().getFullYear();

    for (let year = 1950; year <= currentYear1; year++) {
        years1.push(year);
    }

    const [tableData, setTableData] = useState([]);
    const [tableData1, setTableData1] = useState([]);

    const handleChangejour = (event, value) => {
        setForm(prevForm => ({
            ...prevForm,
            jour: value, 
        }));
    };

    const handleDelete = (index) => {
        Formservice.deletecompetence(index,ref).then((result) => {
            if(result.status){
              setMessage({...message, open: true, color: 'green', messageInfo: "Succès"});
              setTableData(result.data);
            }else{
              setMessage({...message, open: true, color: 'red', messageInfo: "Erreur"});
            }
          })
          .catch((err) => {
            setMessage({...message, open: true, color: 'red', messageInfo: 'erreur'});
          });
    };

    const handleEdit = (index) => {
        let result = tableData1.find(item => item.formation_id === index);
        console.log(result);
        setFormation({
            id:result.formation_id,
            domaine1:result.domaine,
            theme:result.certificat,
            datedebut:"",
            datefin:"",
            fid:result.fid,
            autres:result.fid,
            certificat:result.autres,
            jour:result.jour,
            mois:result.mois,
            annee:result.annee,
            jour1:result.jour1,
            mois1:result.mois1,
            annee1:result.annee1
          });
    }

    const handleDelete1 = (index) => {
        PGIService.deleteformation(index,ref).then((result) => {
            if(result.status){
              setMessage({...message, open: true, color: 'green', messageInfo: "Succès"});
              setTableData1(result.data);
            }else{
              setMessage({...message, open: true, color: 'red', messageInfo: "Erreur"});
            }
          })
          .catch((err) => {
            setMessage({...message, open: true, color: 'red', messageInfo: 'erreur'});
          });
    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        setForm({ ...form, [name]: value });
       };

       const handleChangedesc = (event) => {
        const { name, value } = event.target;
         setDesc({ ...desc, desc: value });
       };

       const handleChange1 = (event) => {
        const { name, value } = event.target;
        console.log("aaa",name,value);
        setFormation({ ...formation, [name]: value });
       };

   
    const handleenvoie = () => {
        if (desc.desc===" " ) {
            setMessage({...message, open: true, color: 'red', messageInfo: 'Veuillez compléter le champ'});
        }
        else{
            if (desc.desc!=="") {
                Formservice.description(ref,desc).then((result) => {
                    if(result.status){
                        setMessage({...message, open: true, color: 'green', messageInfo: "Succès"});
                    }else{
                      setMessage({...message, open: true, color: 'red', messageInfo: "Erreur"});
                    }
                  })
                  .catch((err) => {
                    setMessage({...message, open: true, color: 'red', messageInfo: 'erreur'});
                  });
            }
           
           navigate('/autrecompetence');    
        }
    }

    const handleAjout = () => {
        Formservice.competence(form,ref).then((result) => {
            if(result.status){
                setMessage({...message, open: true, color: 'green', messageInfo: "Succès"});
                setTableData(result.data);
                setForm({
                  domaine:"",
                  compt: "",
                });
            }else{
              setMessage({...message, open: true, color: 'red', messageInfo: "Erreur"});
            }
          })
          .catch((err) => {
            setMessage({...message, open: true, color: 'red', messageInfo: 'erreur'});
          });
       
    };

    const handleAjout1 = () => {
        if (formation.id!=="") {
            
            PGIService.formationsupdate(ref,formation).then((result) => {
                if(result.status){
                    setMessage({...message, open: true, color: 'green', messageInfo: "Succès"});
                    setTableData1(result.data);
                    setForm({
                        domaine1:"",
                        theme:"",
                        datedebut:"",
                        datefin:"",
                        fid:"",
                        autres:"",
                        certificat:""
                    });
                }else{
                  setMessage({...message, open: true, color: 'red', messageInfo: "Erreur"});
                }
              })
              .catch((err) => {
                setMessage({...message, open: true, color: 'red', messageInfo: 'erreur'});
              });
        } else {
            
       
        PGIService.formations(ref,formation).then((result) => {
            if(result.status){
                setMessage({...message, open: true, color: 'green', messageInfo: "Succès"});
                setTableData1(result.data);
                setForm({
                    domaine1:"",
                    theme:"",
                    datedebut:"",
                    datefin:"",
                    fid:"",
                    autres:"",
                    certificat:""
                });
            }else{
              setMessage({...message, open: true, color: 'red', messageInfo: "Erreur"});
            }
          })
          .catch((err) => {
            setMessage({...message, open: true, color: 'red', messageInfo: 'erreur'});
          });
        }
    //     setFormation({
    //         id:"",
    //         domaine1:"",
    //         theme:"",
    //         datedebut:"",
    //         datefin:"",
    //         fid:"",
    //         autres:"",
    //         certificat:"",
    //         jour:"",
    //         mois:"",
    //         annee:"",
    //         jour1:"",
    //         mois1:"",
    //         annee1:""});
    };

    function handleCloseMessage() {
        setMessage({ ...message, open: false });
      }

      const handleretour = () => {
        navigate('/experience');
      }

      useEffect(() => {
        Formservice.details(ref).then((result) => {
          if (result && result.data) {
               setIdentif(result.data);
               setDesc({ ...desc, desc: result.data.description });
          } else {      
          }
        });
        Formservice.getcompetence(ref).then((result) => {
            if (result && result.data) {
                 setTableData(result.data);
            } else {      
            }
          });
          Formservice.getformation(ref).then((result) => {
            if (result && result.data) {
                 setTableData1(result.data);
            } else {      
            }
          });

      }, []);

    return (
        <div style={{ display: 'grid', gridTemplateRows: 'auto 1fr auto', minHeight: '100vh', backgroundColor: "white" }}>
            <Message
                open={open}
                color={color}
                messageInfo={messageInfo}
                handleClose={handleCloseMessage}
            />
            <header style={{ height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img src={logo} alt='logo' style={{ maxWidth: "10%", maxHeight: "20%", marginLeft: "40%", marginBottom: "20px" }} />
            </header>
            <div style={{ padding: '20px', borderRadius: "20px", borderColor: "#0747a1", borderStyle: "solid", borderWidth: "3px", height: "auto", width: "60vw", marginLeft: "auto", marginRight: "auto", marginTop: "auto", marginBottom: "auto", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <main>
                    <Grid container item xs={12}> 
                      <img src={logo} className="App-logo" alt="logo" style={{ maxWidth: "10%", maxHeight: "20%" ,marginLeft:"40%" }} />
                    </Grid>
                    <Grid container spacing={3} justify="center">
                        <h1 style={{ marginBottom: "10px" }}> Compétences et Formation</h1>
                        <Grid container spacing={3}>
                                <Grid item xs={5} style={{ marginBottom:"10px"}} justify="center">
                                <fieldset>
                                    <legend>Identifiant</legend>
                               
                                        <label style={{textAlign:"center"}}>
                                                Réference du Dossier :{ref}
                                        </label>
                                        <br/>
                                        <label >
                                                Nom : {identif.nom}
                                        </label>
                                        <br/>
                                        { prefix!=="CI"&&
                                            <label >
                                                Poste: {identif.poste}
                                             </label>
                                        }
                                        
                                        <br/>
                                      </fieldset>
                                </Grid>
                                <Grid item xs={7} style={{ marginBottom:"20px",color:"red"}}>
                                    <fieldset>
                                        <legend>NB</legend>
                                        <label >
                                            - Tous les champs avec * sont obligatoires.
                                            </label>
                                            <br/>
                                            <label >
                                            - Le bouton Ajouter permet d'enregistrer les études.
                                            </label>
                                            
                                            <br/>
                                            <label >
                                            - Veuillez cliquer sur suivant pour passer au suivant.
                                            </label>
                                    </fieldset>
                                    </Grid>     
                                </Grid>
                                <Grid  item xs={12}> 
                                    <fieldset>
                                        <legend> Mot clé pour te décrire</legend> 
                                           <Grid container spacing={3}>
                                            <Grid item xs={3}>
                                                    <label >
                                                Description * :
                                                    </label>
                                                </Grid>
                                                <Grid item xs={9}>
                                                <TextField style={{ width: '100%', marginBottom: "15px" }} id="outlined-basic" size="small" label="Sépare le mot par ," variant="outlined" name="desc" value={desc.desc} onChange={handleChangedesc}  />
                                                </Grid>
                                            </Grid>
                                        </fieldset>
                                </Grid>
                        {/* <fieldset>
                            <legend> Compétences</legend>
                            <TableContainer component={Paper} style={{ marginTop: '10px' ,marginBottom: '20px'}}>
                                  <Table>
                                      <TableHead>
                                          <TableRow style={{backgroundColor:"gray",padding:'0px'}}>
                                              <TableCell>Domaines de compétences</TableCell>
                                              <TableCell>Compétences</TableCell>
                                              <TableCell>Action</TableCell>
                                          </TableRow>
                                      </TableHead>
                                      <TableBody>
                                          {tableData.map((data, index) => (
                                              <TableRow key={index}>
                                                  <TableCell>{data.domaine}</TableCell>
                                                  <TableCell>{data.competence}</TableCell>
                                                  <TableCell>
                                                      <IconButton aria-label="delete" color="secondary" onClick={() => handleDelete(data.competence_id)} >
                                                          <DeleteIcon />
                                                      </IconButton>
                                                  </TableCell>
                                              </TableRow>
                                          ))}
                                      </TableBody>
                                  </Table>
                              </TableContainer>
                              <Grid container spacing={3}>
                                
                              <Grid item xs={3}>
                                    <label >
                                      Domaines de compétences * :
                                    </label>
                                </Grid>
                                <Grid item xs={9}>
                                <TextField style={{ width: '100%', marginBottom: "15px" }} id="outlined-basic" size="small" label="Domaines de compétences" variant="outlined" name="domaine" value={form.domaine} onChange={handleChange}  />
                                </Grid>
                                
                                <Grid item xs={3}>
                                    <label >
                                          Compétences * :
                                    </label>
                                </Grid>
                                <Grid item xs={9}>
                                <TextField style={{ width: '100%', marginBottom: "30px" }} id="outlined-basic" size="small" label="Compétences" variant="outlined" name="compt" value={form.compt} onChange={handleChange} />
                                </Grid>
                             </Grid>
                            <Button variant="outlined" color="primary" style={{ fontWeight: 400, marginLeft: "80%", marginBottom: "10px" }} onClick={handleAjout}>Ajouter</Button>
                        </fieldset> */}
                   
                        {prefix==="CI"&&
                        <fieldset>
                            <legend> FORMATIONS</legend>
                            <TableContainer component={Paper} style={{ marginTop: '10px' ,marginBottom: '20px'}}>
                                  <Table>
                                      <TableHead>
                                          <TableRow style={{backgroundColor:"gray",padding:'0px'}}>
                                              <TableCell>Domaines de formations</TableCell>
                                              <TableCell>Thèmes de Formation</TableCell>
                                              <TableCell>Période</TableCell>
                                              <TableCell>FID</TableCell>
                                              <TableCell>Autres</TableCell>
                                              <TableCell>Certificats /Attestations Obtenus</TableCell>
                                              <TableCell>Action</TableCell>
                                          </TableRow>
                                      </TableHead>
                                      <TableBody>
                                          {tableData1.map((data, index) => (
                                              <TableRow key={index}>
                                                  <TableCell>{data.domaine}</TableCell>
                                                  <TableCell>{data.theme}</TableCell>
                                                  <TableCell>
                                                    {data.jour !== "" && data.mois !== "" && data.annee !== "" ?
                                                        `${data.jour} ${data.mois} ${data.annee}` :
                                                        (data.jour === "" && data.mois !== "" && data.annee !== "" ?
                                                            `${data.mois} ${data.annee}` :
                                                            (data.jour === "" && data.mois === "" && data.annee !== "" ?
                                                                `${data.mois} ${data.annee}` :
                                                                ""
                                                            )
                                                        )
                                                    }-
                                                    {data.jour1 !== "" && data.mois1 !== "" && data.annee1 !== "" ?
                                                        `${data.jour1} ${data.mois1} ${data.annee1}` :
                                                        (data.jour1 === "" && data.mois1 !== "" && data.annee1 !== "" ?
                                                            `${data.mois1} ${data.annee1}` :
                                                            (data.jour1 === "" && data.mois1 === "" && data.annee1 !== "" ?
                                                                `${data.mois1} ${data.annee1}` :
                                                                ""
                                                            )
                                                        )
                                                    }
                                                </TableCell>

                                                  <TableCell>{data.fid}</TableCell>
                                                  <TableCell>{data.autres}</TableCell>
                                                  <TableCell>{data.certificat}</TableCell>
                                                  <TableCell>
                                                      <IconButton aria-label="delete" color="primary" onClick={() => handleEdit(data.formation_id)}>
                                                        <CreateTwoTone />
                                                      </IconButton>
                                                      <IconButton aria-label="delete" color="secondary" onClick={() => handleDelete1(data.formation_id)} >
                                                          <DeleteIcon />
                                                      </IconButton>
                                                  </TableCell>
                                              </TableRow>
                                          ))}
                                      </TableBody>
                                  </Table>
                              </TableContainer>
                              <Grid container spacing={3}>
                              <Grid item xs={3}>
                                    <label >
                                       Domaines de formations * :
                                    </label>
                                </Grid>
                                <Grid item xs={9}>
                                <TextField style={{ width: '100%', marginBottom: "15px" }} id="outlined-basic" size="small" variant="outlined" name="domaine1" value={formation.domaine1} onChange={handleChange1}  />
                                </Grid>
                                
                                <Grid item xs={3}>
                                    <label >
                                       Thèmes de Formation * :
                                    </label>
                                </Grid>
                                <Grid item xs={9}>
                                <TextField style={{ width: '100%', marginBottom: "30px" }} id="outlined-basic" size="small"  variant="outlined" name="theme" value={formation.theme} onChange={handleChange1} />
                                </Grid>
                                <Grid item xs={3}>
                                    <label >
                                       Période * :
                                    </label>
                                </Grid>
                                <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined" size="small" style={{ width: '100%', marginBottom: "15px" }}>
                                        <InputLabel id="demo-simple-select-outlined-label">
                                            Jour
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            label="Jour"
                                            value={formation.jour}
                                            name="jour"
                                            onChange={handleChange1}
                                        >
                                            {years.map((year, index) => (
                                                <MenuItem key={index} value={year}>{year}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined" size="small" style={{ width: '100%', marginBottom: "15px" }}>
                                        <InputLabel id="demo-simple-select-outlined-label">
                                           Mois
                                        </InputLabel>
                                        <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        label="direction"
                                        value={formation.mois}
                                        name="mois"
                                        onChange={handleChange1}
                                        >
                                            <MenuItem value="Janvier">Janvier</MenuItem>
                                            <MenuItem value="Février">Février</MenuItem>
                                            <MenuItem value="Mars">Mars</MenuItem>
                                            <MenuItem value="Avril">Avril</MenuItem>
                                            <MenuItem value="Mai">Mai</MenuItem>
                                            <MenuItem value="Juin">Juin</MenuItem>
                                            <MenuItem value="Juillet">Juillet</MenuItem>
                                            <MenuItem value="Aôut">Aôut</MenuItem>
                                            <MenuItem value="Septembre">Septembre</MenuItem>
                                            <MenuItem value="Octobre">Octobre</MenuItem>
                                            <MenuItem value="Novembre">Novembre</MenuItem>
                                            <MenuItem value="Décembre">Décembre</MenuItem>
                                        </Select>
                                    </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined" size="small" style={{ width: '100%', marginBottom: "15px" }}>
                                        <InputLabel id="demo-simple-select-outlined-label">
                                            Année
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            label="Année"
                                            value={formation.annee}
                                            name="annee"
                                            onChange={handleChange1}
                                        >
                                            {years1.map((year, index) => (
                                                <MenuItem key={index} value={year}>{year}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                       
                                </Grid>
                                <Grid item xs={3}>
                                    <label >
                                         à
                                    </label>
                                </Grid>
                                <Grid item xs={3}>
                                        
                                   <FormControl fullWidth variant="outlined" size="small" style={{ width: '100%', marginBottom: "15px" }}>
                                        <InputLabel id="demo-simple-select-outlined-label">
                                            Jour
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            label="Jour"
                                            value={formation.jour1}
                                            name="jour1"
                                            onChange={handleChange1}
                                        >
                                            {years.map((year, index) => (
                                                <MenuItem key={index} value={year}>{year}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined" size="small" style={{ width: '100%', marginBottom: "15px" }}>
                                        <InputLabel id="demo-simple-select-outlined-label">
                                           Mois
                                        </InputLabel>
                                        <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        label="direction"
                                        value={formation.mois1}
                                        name="mois1"
                                        onChange={handleChange1}
                                        >
                                            <MenuItem value="Janvier">Janvier</MenuItem>
                                            <MenuItem value="Février">Février</MenuItem>
                                            <MenuItem value="Mars">Mars</MenuItem>
                                            <MenuItem value="Avril">Avril</MenuItem>
                                            <MenuItem value="Mai">Mai</MenuItem>
                                            <MenuItem value="Juin">Juin</MenuItem>
                                            <MenuItem value="Juillet">Juillet</MenuItem>
                                            <MenuItem value="Aôut">Aôut</MenuItem>
                                            <MenuItem value="Septembre">Septembre</MenuItem>
                                            <MenuItem value="Octobre">Octobre</MenuItem>
                                            <MenuItem value="Novembre">Novembre</MenuItem>
                                            <MenuItem value="Décembre">Décembre</MenuItem>
                                        </Select>
                                    </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                    <FormControl fullWidth variant="outlined" size="small" style={{ width: '100%', marginBottom: "15px" }}>
                                        <InputLabel id="demo-simple-select-outlined-label">
                                            Année
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            label="Année"
                                            value={formation.annee1}
                                            name="annee1"
                                            onChange={handleChange1}
                                        >
                                            {years1.map((year, index) => (
                                                <MenuItem key={index} value={year}>{year}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                       
                                </Grid>
                                
                                <Grid item xs={3}>
                                    <label >
                                      FID  :
                                    </label>
                                </Grid>
                                <Grid item xs={9}>
                                <TextField style={{ width: '100%', marginBottom: "30px" }} id="outlined-basic" size="small" variant="outlined" name="fid" value={formation.fid} onChange={handleChange1} />
                                </Grid>
                                <Grid item xs={3}>
                                    <label >
                                    Autres  :
                                    </label>
                                </Grid>
                                <Grid item xs={9}>
                                <TextField style={{ width: '100%', marginBottom: "15px" }} id="outlined-basic" size="small"  variant="outlined" name="autres" value={formation.autres} onChange={handleChange1}  />
                                </Grid>
                                
                                <Grid item xs={3}>
                                    <label >
                                      Certificats /Attestations Obtenus  :
                                    </label>
                                </Grid>
                                <Grid item xs={9}>
                                <TextField style={{ width: '100%', marginBottom: "30px" }} id="outlined-basic" size="small"  variant="outlined" name="certificat" value={formation.certificat} onChange={handleChange1} />
                                </Grid>
                             </Grid>
                             {formation.id!==""?
                                <Button variant="outlined" color="primary" style={{ fontWeight: 400, marginLeft: "80%", marginBottom: "10px" }} onClick={handleAjout1}>Modifier</Button>
                               : <Button variant="outlined" color="primary" style={{ fontWeight: 400, marginLeft: "80%", marginBottom: "10px" }} onClick={handleAjout1}>Ajouter</Button>
                             }
                           
                        </fieldset>}
                    </Grid>
                    <Grid container xs={12} style={{marginTop:"40px"}} >
                        <Grid item xs={6} >
                              
                          </Grid>
                        <Grid item xs={2} >
                              <Button variant="outlined"  color="primary" style={{ fontWeight: 600,marginLeft:"40%",marginBottom:"20px" }} onClick={handleretour}>Retour</Button>
                          </Grid>
                          <Grid item xs={4} >
                            <Button variant="outlined"  color="primary" style={{ fontWeight: 600,marginLeft:"40%",marginBottom:"20px" }} onClick={handleenvoie}>Suivant</Button>
                          </Grid>
                          
                    </Grid>
                    
                </main>
                <footer style={{ color: '#0747a1', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    &copy; 2024 FID - FONDS D'INTERVENTION POUR LE DEVELOPPEMENT
                </footer>
            </div>
        </div>
    );
}

export default DomaineCompetence;
