import React ,{useState,useEffect}from 'react';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {Button} from "@material-ui/core";
import logo from './logo.png';
import { useNavigate } from 'react-router-dom';
import Formservice from "./../service/Formservice";
import Message from "./Message";
import { SwipeLeftSharp } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';

function Finaly() {
  const [poste, setPoste] = React.useState([]);
  const location = useLocation();
  //const params = new URLSearchParams(location.search);
  const ref = localStorage.getItem('ref');;
  const parts = ref.split('_');
  const prefix = parts[0];
  const [load,setLoad]=useState(false);
  const [message, setMessage] = React.useState({
    open: false,
    color: "green",
    messageInfo: "",
  });
  const { open, color, messageInfo } = message;
  const [form,setForm]=useState(
    {
        id_poste:"",
        email:""
    }
  );
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };
  
  

  useEffect(() => {
    Formservice.identifiant(ref).then((result) => {
      if (result && result.data) {
           setPoste(result.data);
      } else {
       
      }
    });
  }, []);
  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }
  return (
    <div style={{ display: 'grid', gridTemplateRows: 'auto 1fr auto', minHeight: '100vh',backgroundColor:"white", }}>
      <Message
        open={open}
        color={color}
        messageInfo={messageInfo}
        handleClose={handleCloseMessage}
      />
       <header style={{  height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          
        </header>
        <div style={{  padding: '20px', borderRadius: "20px", borderColor: "#0747a1", borderStyle: "solid", borderWidth: "3px", height: "auto", width: "60vw", marginLeft: "auto", marginRight: "auto", marginTop: "auto", marginBottom: "auto", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <main>
          <Grid container item xs={12}> 
             <img src={logo} className="App-logo" alt="logo" style={{ maxWidth: "10%", maxHeight: "20%" ,marginLeft:"40%" }} />
          </Grid>
          <Grid container item xs={12} justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={12} container justifyContent="center">
              <Typography variant="body1">
                M/Mme {poste.nom},
              </Typography>
            </Grid>

            {prefix === "CI" ? (
              <>
                <Grid item xs={12} container justifyContent="center">
                  <Typography variant="body1">
                    Votre CV a été mis à jour.
                  </Typography>
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  {/* Optionally, add content here */}
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} container justifyContent="center">
                  <Typography variant="body1">
                    Nous accusons réception de votre dossier <b>{poste.poste}</b> sous une référence <b>{ref}</b>. Un email est envoyé pour l'accusé de réception.
                  </Typography>
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <Typography variant="body1">
                    Cliquez sur ce <a href='https://www.fid.mg'>lien</a> pour voir d'autres offres.
                  </Typography>
                </Grid>
              </>
            )}

            <Grid item xs={12} container justifyContent="center">
              <Typography variant="body1">
                Équipe FID
              </Typography>
            </Grid>
          </Grid>
          </main>
          <footer style={{ color: '#0747a1', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            &copy; 2024 FID - FONDS D'INTERVENTION POUR LE DEVELOPPEMENT
          </footer>
        </div>
      </div>

  );
}

export default Finaly;
