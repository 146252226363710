import axios from "axios";

const apiUrl = process.env.REACT_APP_API_PGIURL;

const PGIservice = {
    async details(id) {
        try {
          const result = await axios.get(`${apiUrl}/index/`+id);
          return result.data;
        } catch (error) {
          if (error.response) {
            return error.response.data;
          } else {
            throw error; 
          }
        }
      },
      async formations(id,form) {
        try {
          const result = await axios.post(`${apiUrl}/insertformation/`+id,form);
          return result.data;
        } catch (error) {
          if (error.response) {
            return error.response.data;
          } else {
            throw error; 
          }
        }
      },

      async formationsupdate(id,form) {
        try {
          const result = await axios.post(`${apiUrl}/updateformation/`+id,form);
          return result.data;
        } catch (error) {
          if (error.response) {
            return error.response.data;
          } else {
            throw error; 
          }
        }
      },
      async updatecompt(id,form) {
        try {
          const result = await axios.post(`${apiUrl}/updatecompetences/`+id,form);
          return result.data;
        } catch (error) {
          if (error.response) {
            return error.response.data;
          } else {
            throw error; 
          }
        }
      },
      async deleteformation(id,ref) {
        try {
          const result = await axios.get(`${apiUrl}/formationdelete/`+id+`/`+ref);
          if (result) {
            return result.data;
          }
        } catch (error) {
          if (error.response) {
            return error.response.data;
          }
        }
      },
}
export default PGIservice;