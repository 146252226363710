import React ,{useState,useEffect}from 'react';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, IconButton } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import { CreateTwoTone } from "@material-ui/icons";
import {Button} from "@material-ui/core";
import logo from './logo.png';
import { useNavigate } from 'react-router-dom';
import Formservice from "./../service/Formservice";
import PGIService from "./../service/PGIService";
import Message from "./Message";
import { useLocation } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function Competence() {
    const location = useLocation();
    //const params = new URLSearchParams(location.search);
    const ref = localStorage.getItem('ref');;
    const [compt,setCompt]=useState([]);
    const parts = ref.split('_');
    const prefix = parts[0];
    const[is,setIS]=useState(0);

    const [opens, setOpens] = React.useState(false);

  
    const handleClose = () => {
      setOpens(false);
    };

    const handleUp = () => {
      PGIService.updatecompt(is,modif).then((result) => {
        if(result.status){
          setMessage({...message, open: true, color: 'green', messageInfo: "Succès"});
          Formservice.details(ref).then((result) => {
            if (result && result.data) {
                 setIdentif(result.data);
                 setCompt(result.autreep);
            } else {
             
            }
          });
          setOpens(false);
        }else{
          setMessage({...message, open: true, color: 'red', messageInfo: "Erreur"});
        }
      })
      .catch((err) => {
        setMessage({...message, open: true, color: 'red', messageInfo: 'erreur'});
      });
     
    };
    
   const [message, setMessage] = React.useState({
    open: false,
    color: "green",
    messageInfo: "",
  });
  const { open, color, messageInfo } = message;
  const [form,setForm]=useState(
    {
        id_poste:"",
        email:""
    }
  );
  const initialLanguages = [
    { competence: 'Langue',type:"Français", lire: false, ecrire: false, parler: false },
    { competence: 'Langue',type:"Anglais", lire: false, ecrire: false, parler: false },
  ];
  const [languages, setLanguages] = useState(initialLanguages);

  const handleCheckboxChange = (index, field) => {
    const newLanguages = [...languages];
    if (field === 'lire') {
        newLanguages[index].lire = !newLanguages[index].lire;
      } else if (field === 'ecrire') {
        newLanguages[index].ecrire = !newLanguages[index].ecrire;
      } else if (field === 'parler') {
        newLanguages[index].parler = !newLanguages[index].parler;
      }
      setLanguages(newLanguages);
  };

  const initialInformatique=[
    { competence: 'Informatique',type:"Excel,Word, PowerPoint,Outlook", Excelent: false, Moyen: false,Débutant:false},
    { competence: 'Informatique',type:"Internet, mailing", Excelent: false, Moyen: false,Débutant:false },
  ];
  const [informatique, setInformatique] = useState(initialInformatique);


  const handleCheckboxChangeinfo = (index, field) => {
    const newLanguages = [...informatique];
    if (field === 'Excellent') {
        console.log("aaa");
        newLanguages[index].Excelent = !newLanguages[index].Excelent;
        newLanguages[index].Moyen = false;
        newLanguages[index].Débutant = false;
      } else if (field === 'Moyen') {
        newLanguages[index].Excelent = false;
        newLanguages[index].Moyen = !newLanguages[index].Moyen;
        newLanguages[index].Débutant = false;
      } else if (field === 'Débutant') {
        newLanguages[index].Excelent = false;
        newLanguages[index].Moyen = false;
        newLanguages[index].Débutant = !newLanguages[index].Moyen;
      }
      setInformatique(newLanguages);
  };
  const navigate = useNavigate();

  const initialRows = [
    { domaine: '', competence: '', niveau: '' },
    { domaine: '', competence: '', niveau: '' },
  ];

  const handleChange = (index, field, value) => {
    const newRows = [...rows];
    newRows[index] = { ...newRows[index], [field]: value };
    setRows(newRows);
  };

  const [rows, setRows] = useState(initialRows);

  const handleretour = () => {
    navigate('/competence');
  }
  
  const handleenvoie = () => {
      Formservice.saveautrecompetence(rows,informatique,languages,ref).then((result) => {
        if(result.status){
          setMessage({...message, open: true, color: 'green', messageInfo: "Succès"});
          setTimeout(function () { navigate('/fichier'); }, 4000);
        }else{
          setMessage({...message, open: true, color: 'red', messageInfo: "Erreur"});
        }
      })
      .catch((err) => {
        setMessage({...message, open: true, color: 'red', messageInfo: 'erreur'});
      });

    
  };

  const [identif, setIdentif] = useState({});
  const [modif,setModif]=useState({
        id:"",
        competence:"",
        type:"",
        niveau:""
  });

  const handleChangemodif = (event) => {
    const { name, value } = event.target;
    setModif(prevModif => ({
      ...prevModif,
      [name]: value
    }));
  };

  const handleEdit = (index) => {
     setOpens(true);
     setIS(index);
     let result = compt.find(item => item.id_competence === index);
      setModif(
        {
          competence:result.competence,
          type:result.type,
           niveau:result.niveau,
           id:result.id_competence
        }
      );
  }

  const handleDelete = (index) => {
     Formservice.deleteautrecompetence(index,ref).then((result) => {
      if (result && result.data) {
        setMessage({...message, open: true, color: 'green', messageInfo: "Succès"});
        Formservice.details(ref).then((result) => {
          if (result && result.data) {
               setIdentif(result.data);
               setCompt(result.autreep);
          } else {
           
          }
        });
      } else {
        setMessage({...message, open: true, color: 'red', messageInfo: "Erreur"});
      }
    });
  }

    useEffect(() => {
      Formservice.details(ref).then((result) => {
        if (result && result.data) {
             setIdentif(result.data);
             setCompt(result.autreep);
        } else {
         
        }
      });
    }, []);
  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }
  return (
    <div style={{ display: 'grid', gridTemplateRows: 'auto 1fr auto', minHeight: '100vh',backgroundColor:"white", }}>
      <Message
        open={open}
        color={color}
        messageInfo={messageInfo}
        handleClose={handleCloseMessage}
      />
       <header style={{  height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          
        </header>
        <div style={{  padding: '20px', borderRadius: "20px", borderColor: "#0747a1", borderStyle: "solid", borderWidth: "3px", height: "auto", width: "60vw", marginLeft: "auto", marginRight: "auto", marginTop: "auto", marginBottom: "auto", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <main>
          <Grid container item xs={12}> 
             <img src={logo} className="App-logo" alt="logo" style={{ maxWidth: "10%", maxHeight: "20%" ,marginLeft:"40%" }} />
          </Grid>
            <Grid container item xs={12}  justify="center">
               <Grid container item xs={12} justify="center">
                   <h1 style={{ marginBottom:"10px"}}>Autres compétences pertinentes </h1>
                </Grid>
              
                <Grid container spacing={3} style={{ width: "55vw"}}>
                                <Grid item xs={5} style={{ marginBottom:"10px"}} justify="center">
                                <fieldset>
                                    <legend>Identifiant</legend>
                               
                                        <label style={{textAlign:"center"}}>
                                                Réference du Dossier :{ref}
                                        </label>
                                        <br/>
                                        <label >
                                                Nom : {identif.nom}
                                        </label>
                                        <br/>
                                        {prefix!=="CI"&&
                                          <label >
                                          Poste : {identif.poste}
                                          </label>
                                        }
                                       
                                        <br/>
                                      </fieldset>
                                </Grid>
                                <Grid item xs={7} style={{ marginBottom:"20px",color:"red"}}>
                                    <fieldset>
                                        <legend>NB</legend>
                                        <label >
                                            - Tous les champs avec * sont obligatoires.
                                            </label>
                                            <br/>
                                            <label >
                                            - Le bouton Ajouter permet d'enregistrer les experience.
                                            </label>
                                            
                                            <br/>
                                           
                                            <label >
                                            - Veuillez cliquer sur suivant pour passer au suivant.
                                            </label>
                                    </fieldset>
                                    </Grid>     
                                </Grid>
                                {compt.length>0 &&
                                  <TableContainer component={Paper} style={{ marginTop: '10px' ,marginBottom: '20px', width: "60vw"}}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow style={{backgroundColor:"gray",padding:'0px'}}>
                                                                <TableCell align="center" colSpan={3}>Autres compétences pertinentes  </TableCell>
                                                                <TableCell>Actions</TableCell>
                                                                {/* <TableCell>Niveau</TableCell> */}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                        {compt
                                                          .filter((data, index, self) =>
                                                              index === self.findIndex((t) => (
                                                                  t.competence === data.competence && t.type === data.type && t.niveau === data.niveau
                                                              ))
                                                          )
                                                          .map((data, index) => (
                                                              <TableRow key={index}>
                                                                  <TableCell>{data.competence}</TableCell>
                                                                  <TableCell>{data.type}</TableCell>
                                                                  <TableCell>{data.niveau}</TableCell>
                                                                  <TableCell>
                                                                    <IconButton aria-label="delete" color="primary" onClick={() => handleEdit(data.id_competence)}>
                                                                        <CreateTwoTone />
                                                                    </IconButton>
                                                                    <IconButton aria-label="delete" color="secondary" onClick={() => handleDelete(data.id_competence)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </TableCell>
                                                              </TableRow>
                                                          ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>}
                    <TableContainer component={Paper} style={{ marginTop: '10px' ,marginBottom: '20px', width: "55vw"}}>
                       <Table>
                       <TableHead>
                            <TableRow style={{backgroundColor:"gray",padding:'0px'}}>
                                  <TableCell align="center" colSpan={3}>Autres compétences pertinentes </TableCell>
                                  {/* <TableCell></TableCell>
                                  <TableCell></TableCell>               */}
                            </TableRow>
                         </TableHead>
                            <TableBody>
                            {languages.map((langue, index) => (
                                  <TableRow key={index}>
                                                 {index === 0 && (
                                                    <TableCell rowSpan={languages.filter(l => l.competence === langue.competence).length}>
                                                    {langue.competence}
                                                    </TableCell>
                                                )}
                                                <TableCell>{langue.type}</TableCell>
                                                <TableCell style={{alignItems:"center"}}> 
                                                    <input
                                                    type="checkbox"
                                                    checked={langue.lire}
                                                    onChange={() => handleCheckboxChange(index, 'lire')}
                                                    />
                                                    Lire
                                                    <input
                                                    type="checkbox"
                                                    checked={langue.ecrire}
                                                    onChange={() => handleCheckboxChange(index, 'ecrire')}
                                                    />
                                                    Ecrire

                                                    <input
                                                    type="checkbox"
                                                    checked={langue.parler}
                                                    onChange={() => handleCheckboxChange(index, 'parler')}
                                                    />
                                                    Parler
                                                    </TableCell>
                                                </TableRow>
                               
                            ))}

                          {informatique.map((ifs, index) => (
                                  <TableRow key={index}>
                                                {index === 0 && (
                                                    <TableCell rowSpan={informatique.filter(l => l.competence === ifs.competence).length}>
                                                    {ifs.competence}
                                                    </TableCell>
                                                )}
                                                <TableCell>{ifs.type}</TableCell>
                                                <TableCell style={{alignItems:"center"}}> 
                                                    <input
                                                    type="checkbox"
                                                    checked={ifs.Excelent}
                                                     onChange={() => handleCheckboxChangeinfo(index,"Excellent")}
                                                    />
                                                    Excellent 
                                                    <input
                                                    type="checkbox"
                                                    checked={ifs.Moyen}
                                                    onChange={() => handleCheckboxChangeinfo(index,"Moyen")}
                                                    />
                                                    Moyen
                                                    <input
                                                    type="checkbox"
                                                    checked={ifs.Débutant}
                                                    onChange={() => handleCheckboxChangeinfo(index,"Débutant")}
                                                    />
                                                    Débutant
                                                    </TableCell>
                                                </TableRow>
                               
                            ))}
                           {rows.map((row, index) => (
                                <TableRow key={index}>
                                <TableCell>
                                    <TextField
                                    name={`domaine${index + 1}`} // Nom du champ pour l'identification
                                    placeholder='Autre domaine'
                                    size="small"
                                    multiline
                                    rows={5}
                                    value={row.domaine}
                                    onChange={(e) => handleChange(index, 'domaine', e.target.value)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                    name={`competence${index + 1}`} // Nom du champ pour l'identification
                                    placeholder='Compétence'
                                    size="small"
                                    multiline
                                    rows={5}
                                    value={row.competence}
                                    onChange={(e) => handleChange(index, 'competence', e.target.value)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                    name={`niveau${index + 1}`} // Nom du champ pour l'identification
                                    placeholder='Niveau'
                                    size="small"
                                    multiline
                                    rows={5}
                                    value={row.niveau}
                                    onChange={(e) => handleChange(index, 'niveau', e.target.value)}
                                    />
                                </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                            </Table>
                        </TableContainer>
                        </Grid>
              <Grid container xs={12} >
                   <Grid item xs={6} >
                        
                    </Grid>
                   <Grid item xs={2} >
                        <Button variant="outlined"  color="primary" style={{ fontWeight: 600,marginLeft:"40%",marginBottom:"20px" }} onClick={handleretour}>Retour</Button>
                    </Grid>
                    <Grid item xs={4} >
                      <Button variant="outlined"  color="primary" style={{ fontWeight: 600,marginLeft:"40%",marginBottom:"20px" }} onClick={handleenvoie}>Suivant</Button>
                    </Grid>
                    
              </Grid>
              <Dialog
                open={opens}
                onClose={handleClose}
               maxWidth="sm"
              >
                <DialogTitle>Modification</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    name="competence"
                    value={modif.competence}
                    label="Competence"
                    fullWidth
                    size='small'
                    variant="standard"
                    onChange={handleChangemodif}
                  />

                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    name="type"
                    value={modif.type}
                    label="type"
                    fullWidth
                    variant="standard"
                    onChange={handleChangemodif}
                  />
                   <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    name="niveau"
                    value={modif.niveau}
                    label="niveau"
                    fullWidth
                    variant="standard"
                    onChange={handleChangemodif}
                  />
                </DialogContent>
                <DialogActions>
                  <Button variant="outlined"  color="primary" onClick={handleClose}>Annuler</Button>
                  <Button variant="outlined"  color="primary" onClick={handleUp}>Modifier</Button>
                </DialogActions>
              </Dialog>
          </main>
          <footer style={{ color: '#0747a1', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            &copy; 2024 FID - FONDS D'INTERVENTION POUR LE DEVELOPPEMENT
          </footer>
        </div>
      </div>

  );
}

export default Competence;
