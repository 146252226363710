import React, { useState,useEffect } from 'react';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Autocomplete from '@mui/material/Autocomplete';
import {FormControl,
    Select,
    MenuItem,
    InputLabel,
    Button, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, IconButton } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import logo from './logo.png';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Message from "./Message";
import Formservice from "./../service/Formservice";

function Fichier() {
    const navigate = useNavigate();
    const location = useLocation();
    // const params = new URLSearchParams(location.search);
    const ref =localStorage.getItem('ref');;
    const parts = ref.split('_');
    const prefix = parts[0];
    const [valeur, setValeur] = useState({});
    const [message, setMessage] = React.useState({
        open: false,
        color: "green",
        messageInfo: "",
      });
      const { open, color, messageInfo } = message;
    const [form, setForm] = useState({
      signature: "",
        photo:"",
        lm: "",
        nif: "",
        stat: "",
        date_nif: "",
        num_nif:"",
        num_stat:""
    });

  
    function onFileChange(event) {
        const { name, value } = event.target;
        const file = event.target.files[0];
        setForm({
            ...form,
            [name]: file
        });
    }

    
    
    function calculateOccurrences(value) {
      if (value) {
      const cin = value.replace(/[^\d]/g, "");
      const number = cin.length;
          if (number < 5) {
            return cin;
          }
          if (number < 7) {
            return `${cin.slice(0, 5)} ${cin.slice(5, 7)}`;
          }
          if (number < 11) {
            return `${cin.slice(0, 5)} ${cin.slice(5, 7)} ${cin.slice(7, 11)}`;
          }
          if (number < 12) {
            return `${cin.slice(0, 5)} ${cin.slice(5, 7)} ${cin.slice(7, 11)} ${cin.slice(11, 12)}`;
          }

          return `${cin.slice(0, 5)} ${cin.slice(5, 7)} ${cin.slice(7, 11)} ${cin.slice(11, 12)} ${cin.slice(12, 17)}`;
        } else {
          return "";
        }
    }

    function handleChange(event) {
      const { name, value } = event.target;
      

      if (form.num_stat) {
        const newval = calculateOccurrences(value);
        setForm({
          ...form,
          [name]: newval
      });
      }
      else
      {
        setForm({
          ...form,
          [name]: value
      });
      }
  }

    const handleenvoie = () => {
      //  if (valeur.file_cv!=="") {
      //     setMessage({...message, open: true, color: 'green', messageInfo: "Succès"});
      //     setTimeout(function () { navigate('/resume?ref='+ref); }, 4000);
      //  } else {
            if ( (prefix!=="CI"&&form.lm=="" )  ) {
              setMessage({...message, open: true, color: 'red', messageInfo: 'Veuillez compléter le champ'});
          }
          else{
        
              Formservice.savefile(form,ref).then((result) => {
                  if(result.status){
                    setMessage({...message, open: true, color: 'green', messageInfo: "Succès"});
                    setTimeout(function () { navigate('/resume'); }, 4000);
                  }else{
                    setMessage({...message, open: true, color: 'green', messageInfo: "Succès"});
                    setTimeout(function () { navigate('/resume'); }, 4000);
                  }
                })
                .catch((err) => {
                  setMessage({...message, open: true, color: 'green', messageInfo: "Succès"});
                  setTimeout(function () { navigate('/resume'); }, 4000);
                });
          }
      //  }
        
    }

    function handleCloseMessage() {
        setMessage({ ...message, open: false });
      }
      const [identif, setIdentif] = useState({});

      const handleretour = () => {
        navigate('/autrecompetence');
      }

      useEffect(() => {
        Formservice.identifiant(ref).then((result) => {
          if (result && result.data) {
               setIdentif(result.data);
               console.log(result.data);
               setForm ({
                signature: "",
                  photo:"",
                  lm: "",
                  nif: "",
                  stat: "",
                  date_nif:result.data.date_nif,
                  num_nif:result.data.num_carte_fiscal,
                  num_stat:result.data.numero_carte_stat
              });
          } else {
           
          }
        });
        Formservice.details(ref).then((result) => {
          if (result && result.data) {
            console.log(result.data);
              //  setIdentif(result.data);   
              setValeur(result.data);
          } else {
           
          }
        });
      }, []);

    return (
        <div style={{ display: 'grid', gridTemplateRows: 'auto 1fr auto', minHeight: '100vh', backgroundColor: "white" }}>
            <Message
                open={open}
                color={color}
                messageInfo={messageInfo}
                handleClose={handleCloseMessage}
            />
            <header style={{ height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img src={logo} alt='logo' style={{ maxWidth: "10%", maxHeight: "20%", marginLeft: "40%", marginBottom: "20px" }} />
            </header>
            <div style={{ padding: '20px', borderRadius: "20px", borderColor: "#0747a1", borderStyle: "solid", borderWidth: "3px", height: "auto", width: "60vw", marginLeft: "auto", marginRight: "auto", marginTop: "auto", marginBottom: "auto", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <main>
                    <Grid container item xs={12}> 
                      <img src={logo} className="App-logo" alt="logo" style={{ maxWidth: "10%", maxHeight: "20%" ,marginLeft:"40%" }} />
                    </Grid>
                    <Grid container spacing={3} justify="center">
                       <Grid container item xs={12} justify="center"> 
                           <h1 style={{ marginBottom: "10px" }}>Fichiers</h1>
                        </Grid>
                        <Grid container spacing={3} style={{ width: "55vw"}}>
                                <Grid item xs={5} style={{ marginBottom:"10px"}} justify="center">
                                <fieldset>
                                    <legend>Identifiant</legend>
                               
                                        <label style={{textAlign:"center"}}>
                                                Réference du Dossier :{ref}
                                        </label>
                                        <br/>
                                        <label >
                                                Nom : {identif.nom}
                                        </label>
                                        <br/>
                                        {prefix!=='CI'&&
                                              <label >
                                                Poste : {identif.poste}
                                              </label>
                                        }
                                        
                                        <br/>
                                      </fieldset>
                                </Grid>
                                <Grid item xs={7} style={{ marginBottom:"20px",color:"red"}}>
                                    <fieldset>
                                        <legend>NB</legend>
                                        <label >
                                            - Tous les champs avec * sont obligatoires.
                                            </label>
                                            <br/>
                                            <label >
                                            - Le bouton Ajouter permet d'enregistrer les experience.
                                            </label>
                                            
                                            <br/>
                                            <label >
                                            - Veuillez cliquer sur suivant pour passer au suivant.
                                            </label>
                                    </fieldset>
                                    </Grid>     
                                </Grid>
                        <fieldset style={{ width: "55vw"}}>
                            <legend>Fichiers</legend>
                                <Grid container spacing={3}>
                                 {prefix!=='CI'&&
                                   <>
                                      <Grid item xs={3}>
                                        <label >
                                            Lettre de motivation *:
                                        </label>
                                      </Grid>
                                      <Grid item xs={2}>
                                      </Grid>
                                      <Grid item xs={7}>
                                          <input type="file" name="lm" onChange={onFileChange} />
                                      </Grid>
                                   </>
                                 }
                                  
                                  <Grid item xs={3}>
                                    <label >
                                        Scan signature sous forme image  :
                                    </label>
                                  </Grid>
                                  <Grid item xs={2}>
                                  </Grid>
                                  <Grid item xs={7}>
                                      <input type="file" name="signature" onChange={onFileChange} accept="image/*" />
                                  </Grid>
                                  <Grid item xs={3}>
                                    <label >
                                       Photo d'identité :
                                    </label>
                                  </Grid>
                                  <Grid item xs={2}>
                                  </Grid>
                                  <Grid item xs={7}>
                                      <input type="file" name="photo" onChange={onFileChange} accept="image/*" />
                                  </Grid>
                                  
                                  <Grid item xs={3}>
                                    <label >
                                    Date mise à jour NIF valable jusqu'au :
                                    </label>
                                  </Grid>
                                  <Grid item xs={2}>
                                  </Grid>
                                  <Grid item xs={7}>
                                    <TextField
                                    name="date_nif"
                                    size="small"
                                   // value={form.date_nif}
                                      variant="outlined"
                                      style={{ width: '100%' }}
                                      type='date'
                                      onChange={handleChange}
                                    />
                                  </Grid>
                                  <Grid item xs={3}>
                                    <label >
                                    Numéro NIF :
                                    </label>
                                  </Grid>
                                  <Grid item xs={2}>
                                  </Grid>
                                  <Grid item xs={7}>
                                  <TextField
                                    name="num_nif"
                                    size="small"
                                    value={form.num_nif}
                                      variant="outlined"
                                      style={{ width: '100%' }}
                                      onChange={handleChange}
                                    />
                                  </Grid>
                                  <Grid item xs={3}>
                                    <label >
                                    Carte NIF :
                                    </label>
                                  </Grid>
                                  <Grid item xs={2}>
                                  </Grid>
                                  <Grid item xs={7}>
                                      <input type="file" name="nif" onChange={onFileChange} />
                                  </Grid>
                                  <Grid item xs={3}>
                                    <label >
                                    Numéro STAT : 
                                    </label>
                                  </Grid>
                                  <Grid item xs={2}>
                                  </Grid>
                                  <Grid item xs={7}>
                                  <TextField
                                    name="num_stat"
                                    size="small"
                                    value={form.num_stat}
                                    inputProps={{ maxLength: 21}}
                                      variant="outlined"
                                      style={{ width: '100%' }}
                                       onChange={handleChange}
                                      />
                                  </Grid>
                                  <Grid item xs={3}>
                                    <label >
                                    Carte STAT :
                                    </label>
                                  </Grid>
                                  <Grid item xs={2}>
                                  </Grid>
                                  <Grid item xs={7}>
                                      <input type="file" name="stat" onChange={onFileChange} />
                                  </Grid>
                        </Grid>
                        </fieldset>
                    </Grid>
                    <Grid container xs={12}  style={{marginTop:"40px"}} >
                   <Grid item xs={6} >
                        
                    </Grid>
                   <Grid item xs={2} >
                        <Button variant="outlined"  color="primary" style={{ fontWeight: 600,marginLeft:"40%",marginBottom:"20px" }} onClick={handleretour}>Retour</Button>
                    </Grid>
                    <Grid item xs={4} >
                      <Button variant="outlined"  color="primary" style={{ fontWeight: 600,marginLeft:"40%",marginBottom:"20px" }} onClick={handleenvoie}>Suivant</Button>
                    </Grid>
                    
              </Grid>
                    
                </main>
                <footer style={{ color: '#0747a1', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    &copy; 2024 FID - FONDS D'INTERVENTION POUR LE DEVELOPPEMENT
                </footer>
            </div>
        </div>
    );
}

export default Fichier;
