import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Accueil from './screens/Accueil';
import Etatcivil from './screens/Etatcivil';
import Etude from './screens/Etude';
import Resume from './screens/Resume';
import Experience from './screens/Experience';
import Competence from './screens/Competence';
import Fichier from './screens/Fichier';
import Finaly from './screens/Finaly';
import DomaineCompetence from './screens/DomaineComptence';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Accueil />} />
        <Route path="/etatcivil" element={<Etatcivil />} />
        <Route path="/etude" element={<Etude />} />
        <Route path="/experience" element={<Experience />} />
        <Route path="/competence" element={<DomaineCompetence />} />
        <Route path="/autrecompetence" element={<Competence />} />
        <Route path="/fichier" element={<Fichier />} />
        <Route path="/resume" element={<Resume />} />
        <Route path="/finaly" element={<Finaly />} />
      </Routes>
    </div>
  );
}

export default App;
