import React, { useState ,useEffect} from 'react';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Autocomplete from '@mui/material/Autocomplete';
import {FormControl,
    Select,
    MenuItem,
    InputLabel,
    Button, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, IconButton } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import { CreateTwoTone } from "@material-ui/icons";
import logo from './logo.png';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Message from "./Message";
import Formservice from "./../service/Formservice";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Phone from "./Phone";
import Alert from "@material-ui/lab/Alert";
import AddIcon from '@mui/icons-material/Add';

function Experience() {
    const navigate = useNavigate();
    const location = useLocation();
    // const params = new URLSearchParams(location.search);
    const ref = localStorage.getItem('ref');;
    const [domaine,setDomaine]=useState([]);
    const [sousdomaine,setSousdomaine]=useState([]);
    const [isautre,setAutres]=useState(false);
    const [plus,setPlus]=useState(false);
    const [number, setNumber] = useState(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ' ', '']);
    const [compt, setCompt] = useState(false);
    const [isPhoneNumberEmpty, setIsPhoneNumberEmpty] = useState(false);
    const [isPhoneNumberLengthNotValid, setPhoneNumberLengthNotValid] = useState(false);
    const apiurl=process.env.REACT_APP_API_FILE;
    const [compt1, setCompt1] = useState(false);
    const [isPhoneNumberEmpty1, setIsPhoneNumberEmpty1] = useState(false);
    const [isPhoneNumberLengthNotValid1, setPhoneNumberLengthNotValid1] = useState(false);
    const [isdom,setIsdom]=useState(false);
    const parts = ref.split('_');
    const prefix = parts[0];
    const [reference, setReference] = useState({
       nom_empl:"",
       email:"",
       num:"",
       nom_empl1:"",
       email1:"",
       num1:""
    });
    const [message, setMessage] = React.useState({
        open: false,
        color: "green",
        messageInfo: "",
      });
      const { open, color, messageInfo } = message;

      function convertDate(inputDate) {
        let parts = inputDate.split("/");
        let formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
        return formattedDate;
      }

    const [form, setForm] = useState({
        id:"",
        date_debut: "",
        date_fin: "",
        entreprise: "",
        domaine: null,
        sous_domaine: null,
        poste:"",
        mission:"",
        attestation:[],
        ref:"",type:"",
        fin_contrat:""
    });

    const [tableData, setTableData] = useState([]);


    function handleFocusPhoneNumber() {
      setIsPhoneNumberEmpty(false);
      setPhoneNumberLengthNotValid(false);
    }

    function handleFocusPhoneNumber1() {
      setIsPhoneNumberEmpty1(false);
      setPhoneNumberLengthNotValid1(false);
    }

    const handleChange11=(event)=>{
      const { name, value } = event.target;
      var i = 0;
        var a = 0;
        for (let index = 0; index < value.length; index++) {
          for (let indexs = 0; indexs < number.length; indexs++) {
            if (number[indexs] == value[index]) {
              i++;
            }
          }
        }
        if (i != value.length) {
          setCompt1(true);
        }
        else {
          setCompt1(false);
          setReference({ ...reference, [name]: value });
         
        }
    }

    const handleChange1=(event)=>{
    const { name, value } = event.target;
    var i = 0;
      var a = 0;
      for (let index = 0; index < value.length; index++) {
        for (let indexs = 0; indexs < number.length; indexs++) {
          if (number[indexs] == value[index]) {
            i++;
          }
        }
      }
      if (i != value.length) {
        setCompt(true);
      }
      else {
        setCompt(false);
        setReference({ ...reference, [name]: value });
       
      }
  }
    const handleChange = (event) => {
        const { name, value } = event.target;
        setForm({ ...form, [name]: value });
    };

    const handleplus = () => {
       setPlus(true);
    };

    const handleChangeref = (event) => {
      const { name, value } = event.target;
      setReference({ ...reference, [name]: value });
     };

    function onFileChange(event){ 
      setForm({ ...form, "attestation": event.target.files[0] });
    }

    const handleChangedomaine=(event,value)=>{
      const idvar=value.id_domaine;
      setForm({ ...form, "domaine": value });
      if (value.name==="Autres") {
         setIsdom(true);
      }
      Formservice.sousdomaine(idvar).then((result) => {
        if (result && result.data) {
             setSousdomaine(result.data);
        } else {
         
        }
      });
    }

    const handleChangeautre = (event) => {
      const newValue = event.target.value;
      setForm({ ...form, sous_domaine: { name: newValue } });
    };

    const handleChangeautredomaine = (event) => {
      const newValue = event.target.value;
      setForm({ ...form, domaine: { name: newValue } });
    };

    const [identif, setIdentif] = useState({});
    const [details, setDetails] = useState([]);
  
    useEffect(() => {
      Formservice.details(ref).then((result) => {
        if (result && result.data) {
             setIdentif(result.data);
             setTableData(result.exp);
        } else {
         
        }
      });
    }, []);

    const handleSave = () => {
      if (form.id!=="") {
        Formservice.updateexperience(form,ref,reference,form.id).then((result) => {
          if(result.status){
              setMessage({...message, open: true, color: 'green', messageInfo: "Succès"});
              setTableData(result.data);
              setForm({
                date_debut:"",
                date_fin: "",
                entreprise: "",
                domaine:null,
                sous_domaine:null,
                poste:"",
                mission:"",
                attestation:"",
                ref:"",
                type:"",
                fin_contrat:"",
                id:""
              });
              setReference({
                nom_empl:"",
                email:"",
                num:"",
                nom_empl1:"",
                email1:"",
                num1:""
              });
              setPlus(false);
              setAutres(false);
              setIsdom(false);
          }else{
            setMessage({...message, open: true, color: 'red', messageInfo: "Erreur"});
          }
        })
        .catch((err) => {
          setMessage({...message, open: true, color: 'red', messageInfo: 'erreur'});
        });
      } else {
        Formservice.saveexperience(form,ref,reference).then((result) => {
            if(result.status){
                setMessage({...message, open: true, color: 'green', messageInfo: "Succès"});
                setTableData(result.data);
                setForm({
                  date_debut:"",
                  date_fin: "",
                  entreprise: "",
                  domaine:null,
                  sous_domaine:null,
                  poste:"",
                  mission:"",
                  attestation:"",
                  ref:"",
                  type:"",
                  fin_contrat:"",
                  id:""
                });
                setReference({
                  nom_empl:"",
                  email:"",
                  num:"",
                  nom_empl1:"",
                  email1:"",
                  num1:""
                });
                setPlus(false);
                setAutres(false);
                setIsdom(false);
            }else{
              setMessage({...message, open: true, color: 'red', messageInfo: "Erreur"});
            }
          })
          .catch((err) => {
            setMessage({...message, open: true, color: 'red', messageInfo: 'erreur'});
          });
        }
    };

    const handleDelete = (index) => {
      Formservice.deleteexperience(index,ref).then((result) => {
          if(result.status){
            setMessage({...message, open: true, color: 'green', messageInfo: "Succès"});
            setTableData(result.data);
          }else{
            setMessage({...message, open: true, color: 'red', messageInfo: "Erreur"});
          }
        })
        .catch((err) => {
          setMessage({...message, open: true, color: 'red', messageInfo: 'erreur'});
        });
  };

  const handleEdit = (index) => {
      let result = tableData.find(item => item.id_experience === index);
      setForm(
        {
          id:result.id_experience,
          date_debut: convertDate(result.date_debut),
          date_fin: convertDate(result.date_fin),
          entreprise: result.organisme_experience,
          domaine:  { id_domaine: result.domaine_experience,name:result.domaine_experience },
          sous_domaine: { id_sous_domaine: result.sous_domaine_experience ,name:result.sous_domaine_experience },
          poste:result.experience_mission,
          mission:result.description_experience,
          attestation:[],
          ref:"",
          type:result.statut,
          fin_contrat:result.fin_contrat
        });
};

    const handleenvoie = () => {
        if (tableData.length===0) {
            setMessage({...message, open: true, color: 'red', messageInfo: 'Veuillez compléter le champ'});
        }
        else{
            navigate('/competence');
        }
    }

    function handleCloseMessage() {
        setMessage({ ...message, open: false });
      }
      const handleretour = () => {
        navigate('/etude');
      }

      useEffect(() => {
        Formservice.domaine().then((result) => {
          if (result && result.data) {
               setDomaine(result.data);
          } else {
           
          }
        });
        
      }, []);

    return (
        <div style={{ display: 'grid', gridTemplateRows: 'auto 1fr auto', minHeight: '100vh', backgroundColor: "white" }}>
            <Message
                open={open}
                color={color}
                messageInfo={messageInfo}
                handleClose={handleCloseMessage}
            />
            <header style={{ height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img src={logo} alt='logo' style={{ maxWidth: "10%", maxHeight: "20%", marginLeft: "40%", marginBottom: "20px" }} />
            </header>
            <div style={{ padding: '20px', borderRadius: "20px", borderColor: "#0747a1", borderStyle: "solid", borderWidth: "3px", height: "auto", width: "60vw", marginLeft: "auto", marginRight: "auto", marginTop: "auto", marginBottom: "auto", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <main>
                    <Grid container item xs={12} > 
                      <img src={logo} className="App-logo" alt="logo" style={{ maxWidth: "10%", maxHeight: "20%" ,marginLeft:"40%" }} />
                    </Grid>
                    <Grid container spacing={3} justify="center">
                        <Grid container item xs={12} justify="center"> 
                           <h1 style={{ marginBottom: "10px" }}>Expérience professionnelle et stages</h1>
                        </Grid>
                        <Grid container spacing={3} style={{ width: "55vw"}}>
                                <Grid item xs={5} style={{ marginBottom:"10px"}} justify="center">
                                <fieldset>
                                    <legend>Identifiant</legend>
                               
                                        <label style={{textAlign:"center"}}>
                                                Réference du Dossier :{ref}
                                        </label>
                                        <br/>
                                        <label >
                                                Nom : {identif.nom}
                                        </label>
                                        <br/>
                                        {prefix!=='CI'&&
                                          <label >
                                                Poste : {identif.poste}
                                          </label>
                                        }
                                        
                                        <br/>
                                      </fieldset>
                                </Grid>
                                <Grid item xs={7} style={{ marginBottom:"20px",color:"red"}}>
                                    <fieldset>
                                        <legend>NB</legend>
                                        <label >
                                            - Tous les champs avec * sont obligatoires.
                                            </label>
                                            <br/>
                                            <label >
                                            - Le bouton Ajouter permet d'enregistrer les experience.
                                            </label>
                                            
                                            <br/>
                                            <label >
                                            - Veuillez cliquer sur suivant pour passer au suivant.
                                            </label>
                                    </fieldset>
                                    </Grid>     
                                </Grid>
                        <fieldset style={{ width: "55vw"}}>
                            <legend>Experiences</legend>
                            <TableContainer component={Paper} style={{ marginTop: '10px' ,marginBottom: '20px', width: "55vw"}}>
                                  <Table>
                                      <TableHead>
                                          <TableRow style={{backgroundColor:"gray",padding:'0px'}}>
                                              <TableCell>Date début</TableCell>
                                              <TableCell>Date fin</TableCell>
                                              <TableCell>Entreprise</TableCell>
                                              <TableCell>Domaine</TableCell>
                                              <TableCell>Sous_domaine</TableCell>
                                              <TableCell>Statut d'emploi</TableCell>
                                              <TableCell>Poste occupée</TableCell>
                                              <TableCell>Mission</TableCell>
                                              <TableCell>PJ</TableCell>
                                              <TableCell>Action</TableCell>
                                          </TableRow>
                                      </TableHead>
                                      <TableBody>
                                          {tableData.map((data, index) => (
                                                  <TableRow key={index}>
                                                      <TableCell>{data.date_debut}</TableCell>
                                                      {data.date_fin !== '00/00/0000'? (
                                                            <TableCell>{data.date_fin}</TableCell>
                                                        ) : (
                                                            <TableCell>{data.fin_contrat}</TableCell>
                                                        )}   
                                                      <TableCell>{data.organisme_experience}</TableCell>
                                                      <TableCell>{data.domaine_experience}</TableCell>
                                                      <TableCell>{data.sous_domaine_experience}</TableCell> 
                                                      <TableCell>{data.statut}</TableCell> 
                                                      <TableCell>{data.experience_mission}</TableCell>
                                                      <TableCell>{data.description_experience}</TableCell>
                                                      <TableCell>  
                                                        {data.file_experience==""?
                                                          ""
                                                        :  
                                                        <a href={`${apiurl}/uploads/attestation/${data.file_experience}`} target="_blank" rel="noopener noreferrer">PJ</a>
                                                        } 
                                                      </TableCell>
                                                      <TableCell>
                                                             <IconButton aria-label="delete" color="primary" onClick={() => handleEdit(data.id_experience)}>
                                                                <CreateTwoTone />
                                                            </IconButton>
                                                            <IconButton aria-label="delete" color="secondary" onClick={() => handleDelete(data.id_experience)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                  </TableRow>
                                              ))}
                                      </TableBody>
                                  </Table>
                              </TableContainer>
                              <Grid container spacing={3}>
                                  <Grid item xs={3}>
                                    <label >
                                        Date de début * :
                                      </label>
                                  </Grid>
                                  <Grid item xs={9}>
                                      <TextField
                                          fullWidth
                                          style={{ width: '100%' ,marginBottom:"15px"}}
                                          id="outlined-basic"
                                          size="small"
                                          variant="outlined"
                                          type="date"
                                          required
                                          value={form.date_debut}
                                          name="date_debut"
                                          onChange={handleChange}
                                        />
                                      </Grid>
                                  <Grid item xs={3}>
                                    <label >
                                        Date de fin * :
                                    </label>
                                  </Grid>
                                   <Grid item xs={7}>
                                        <TextField
                                          fullWidth
                                          style={{ width: '100%' ,marginBottom:"15px"}}
                                          id="outlined-basic"
                                          size="small"
                                          variant="outlined"
                                          type="date"
                                          required
                                          value={form.date_fin}
                                          name="date_fin"
                                          onChange={handleChange}
                                        />
                                      </Grid>
                                       <Grid item xs={2}>
                                          <FormGroup style={{ width: '100%' ,marginBottom:"15px"}}>
                                          <FormControlLabel
                                              control={<Checkbox value="Présent" checked={form.fin_contrat === 'Présent'} onChange={handleChange} />}
                                              label="Présent" name='fin_contrat'
                                            />
                                          </FormGroup>
                                      </Grid>
                            
                                  <Grid item xs={3}> 
                                      <label >
                                            Entreprise * :
                                      </label>
                                  </Grid >
                                  <Grid item xs={9}>
                                   <TextField required style={{ width: '100%' ,marginBottom:"15px"}} InputProps={{ style: { height: '50px' } }} id="outlined-basic" size="small" value={form.entreprise} label="Entreprise" variant="outlined" name="entreprise" onChange={handleChange}/>
                                   </Grid>
                                   <Grid item xs={3}> 
                                      <label >
                                            Domaine * :
                                      </label>
                                  </Grid >
                                  <Grid item xs={9}> 
                                      <Autocomplete labelId="demo-simple-select-outlined-label" size="small"
                                          options={[...domaine, { id_domaine: "Autres",name:"Autres" }]}
                                          value={form.domaine||null}
                                          getOptionLabel={(option) => option.name}
                                          style={{ width: '100%', marginBottom: "15px" }}
                                          onChange={handleChangedomaine}
                                          renderInput={(params) => <TextField {...params} label="Sélectionnez un domaine" variant="outlined" />}
                                      />
                                   </Grid >
                                   {isdom &&
                                    <>
                                      <Grid item xs={3}>
                                        <label >
                                            Autres domaine * :
                                          </label>
                                      </Grid>
                                      <Grid item xs={9}>
                                      <TextField
                                        fullWidth
                                        id="outlined-basic"
                                        size="small"
                                        label="Autres domaine"
                                        variant="outlined"
                                        name="autre"
                                        onChange={handleChangeautredomaine}
                                      />
                                      </Grid>
                                    </>
                                    }
                                <Grid item xs={3}> 
                                      <label >
                                            Sous_domaine * :
                                      </label>
                                  </Grid >
                                  <Grid item xs={9}> 
                                        <Autocomplete
                                            labelId="demo-simple-select-outlined-label"
                                            size="small"
                                            options={[...sousdomaine, { id_sous_domaine: "Autres",name:"Autres" }]} 
                                            value={form.sous_domaine|| null}
                                            getOptionLabel={(option) => option.name}
                                            style={{ width: '100%', marginBottom: "15px" }}
                                            onChange={(event, newValue) => {
                                                setForm({ ...form, sous_domaine: newValue });
                                                if (newValue.name==="Autres") {
                                                  setAutres(true);
                                                }  
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Sélectionnez un sous_domaine"
                                                    variant="outlined"
                                                />
                                            )}
                                        />

                                 </Grid >
                                 {isautre &&
                                    <>
                                      <Grid item xs={3}>
                                        <label >
                                            Autres sous_domaine * :
                                          </label>
                                      </Grid>
                                      <Grid item xs={9}>
                                      <TextField
                                        fullWidth
                                        id="outlined-basic"
                                        size="small"
                                        label="Autres sous_domaine"
                                        variant="outlined"

                                        name="autre"
                                        onChange={handleChangeautre}
                                      />
                                      </Grid>
                                    </>
                                    }
                                 <Grid item xs={3}> 
                                      <label >
                                         Statut d'emploi * :
                                      </label>
                                  </Grid >
                                  <Grid item xs={9} style={{ fontSize: '20px' }}> 
                                  <FormGroup>
                                       <FormControlLabel
                                          control={<Checkbox value="Consultant" checked={form.type === 'Consultant'} onChange={handleChange} />}
                                          label="Consultant" name='type'
                                        />
                                        <FormControlLabel
                                          control={<Checkbox value="Salarié" checked={form.type === 'Salarié'} onChange={handleChange} />}
                                          label="Salarié" name='type'
                                        />
                                        <FormControlLabel
                                          control={<Checkbox value="Stagiaire" checked={form.type === 'Stagiaire'} onChange={handleChange} />}
                                          label="Stagiaire" name='type'
                                        />
                                      </FormGroup>

                                  </Grid >
                                <Grid item xs={3}> 
                                      <label >
                                            Poste occupée * :
                                      </label>
                                  </Grid >
                                  <Grid item xs={9}> 
                                    <TextField style={{ width: '100%', marginBottom: "30px" }} id="outlined-basic" size="small" label="Poste occupée" variant="outlined" name="poste" value={form.poste} onChange={handleChange} />
                                  </Grid >
                                <Grid item xs={3}> 
                                      <label >
                                            Missions * :
                                      </label>
                                  </Grid >
                                  <Grid item xs={9}> 
                                     <TextField style={{ width: '100%', marginBottom: "10px" }} id="outlined-basic" size="small" label="Missions" variant="outlined" name="mission" multiline rows={5} value={form.mission} onChange={handleChange} />
                                  </Grid> 
                                  <Grid item xs={5}> 
                                      <label >
                                      Pour obtenir références de ce poste :
                                      </label>
                                  </Grid >
                                  <Grid item xs={7}> 
                                    
                                  </Grid> 
                                  <Grid item xs={3}> 
                                      <label >
                                            Nom et Prénom(s) :
                                      </label>
                                  </Grid >
                                  <Grid item xs={9}> 
                                     <TextField style={{ width: '100%', marginBottom: "30px" }} id="outlined-basic" size="small" label="Nom employeur" variant="outlined" name="nom_empl" multiline rows={2} value={reference.nom_empl} onChange={handleChangeref} />
                                  </Grid> 
                                  <Grid item xs={3}> 
                                      <label >
                                           Email :
                                      </label>
                                  </Grid >
                                  <Grid item xs={9}> 
                                     <TextField style={{ width: '100%', marginBottom: "30px" }} id="outlined-basic" size="small" label="" variant="outlined" name="email" multiline rows={2} value={reference.email} onChange={handleChangeref} />
                                  </Grid> 
                                  <Grid item xs={3}> 
                                      <label >
                                         Numéro téléphone :
                                      </label>
                                  </Grid >
                                  <Grid item xs={9}> 
                                      <Phone x1={3} x2={8} name="num" value={reference.num} onChange={handleChange1} onFocus={handleFocusPhoneNumber} />
                                      {compt && <><Alert severity="error">Le numero de téléphone ne contient pas de lettres</Alert></>}
                                      {isPhoneNumberEmpty && <><Alert severity="error">Ce champ est obligatoire</Alert></>}
                                       {isPhoneNumberLengthNotValid && <><Alert severity="error">Veuillez vérifier votre numéro de téléphone. La longueur n'est pas valide</Alert></>}
                                  </Grid> 
                                  {!plus &&
                                  <>
                                     <Grid item xs={5}> 
                                          <Button onClick={handleplus} >
                                            <AddIcon/>Ajouter plus de références
                                          </Button>
                                      </Grid >
                                      <Grid item xs={7}>     
                                      </Grid>
                                  </>
                                  }
                                  {plus &&
                                      <>
                                          <Grid item xs={3}> 
                                                <label >
                                                    Nom et Prénom(s) :
                                                </label>
                                            </Grid >
                                            <Grid item xs={9}> 
                                              <TextField style={{ width: '100%', marginBottom: "30px" }} id="outlined-basic" size="small" label="Nom employeur" variant="outlined" name="nom_empl1" multiline rows={2} value={reference.nom_empl1} onChange={handleChangeref} />
                                            </Grid> 
                                            <Grid item xs={3}> 
                                                <label >
                                                    Email :
                                                </label>
                                            </Grid >
                                            <Grid item xs={9}> 
                                              <TextField style={{ width: '100%', marginBottom: "30px" }} id="outlined-basic" size="small" label="" variant="outlined" name="email1" multiline rows={2} value={reference.email1} onChange={handleChangeref} />
                                            </Grid> 
                                            <Grid item xs={3}> 
                                                <label >
                                                  Numéro téléphone :
                                                </label>
                                            </Grid >
                                            <Grid item xs={9}> 
                                                <Phone x1={3} x2={8} name="num1" value={reference.num1} onChange={handleChange11} onFocus={handleFocusPhoneNumber1} />
                                                {compt1 && <><Alert severity="error">Le numero de téléphone ne contient pas de lettres</Alert></>}
                                                {isPhoneNumberEmpty1 && <><Alert severity="error">Ce champ est obligatoire</Alert></>}
                                                {isPhoneNumberLengthNotValid1 && <><Alert severity="error">Veuillez vérifier votre numéro de téléphone. La longueur n'est pas valide</Alert></>}
                                            </Grid>
                                      </>
                                  }
                                  <Grid item xs={3}>
                                    <label >
                                        Attestation/Certificat Bonne fin:
                                    </label>
                                  </Grid>
                                  <Grid item xs={9}>
                                      <input type="file" name="attestation" onChange={onFileChange} />
                                  </Grid>
                              </Grid>
                              {form.id===""?
                               <Button variant="outlined" color="primary" style={{ fontWeight: 400, marginLeft: "80%", marginBottom: "10px" }} onClick={handleSave}>Ajouter</Button>
                                : <Button variant="outlined" color="primary" style={{ fontWeight: 400, marginLeft: "80%", marginBottom: "10px" }} onClick={handleSave}>Modifier</Button>
                              }
                           
                        </fieldset>
                    </Grid>
                    <Grid container xs={12} style={{marginTop:"40px"}} >
                        <Grid item xs={6} >
                              
                          </Grid>
                        <Grid item xs={2} >
                              <Button variant="outlined"  color="primary" style={{ fontWeight: 600,marginLeft:"40%",marginBottom:"20px" }} onClick={handleretour}>Retour</Button>
                          </Grid>
                          <Grid item xs={4} >
                            <Button variant="outlined"  color="primary" style={{ fontWeight: 600,marginLeft:"40%",marginBottom:"20px" }} onClick={handleenvoie}>Suivant</Button>
                          </Grid>
                          
                    </Grid>
                    
                </main>
                <footer style={{ color: '#0747a1', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    &copy; 2024 FID - FONDS D'INTERVENTION POUR LE DEVELOPPEMENT
                </footer>
            </div>
        </div>
    );
}

export default Experience;
