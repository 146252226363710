import React ,{useState,useEffect}from 'react';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {Button} from "@material-ui/core";
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, IconButton } from "@material-ui/core";
import logo from './logo.png';
import { useNavigate } from 'react-router-dom';
import Formservice from "./../service/Formservice";
import Message from "./Message";
import { useLocation } from 'react-router-dom';

function Resume() {
  const [poste, setPoste] = React.useState([]);
  const location = useLocation();
//  const params = new URLSearchParams(location.search);
 const ref = localStorage.getItem('ref');;
 const parts = ref.split('_');
 const prefix = parts[0];
 const apiurl=process.env.REACT_APP_API_FILE;
 const [identity,setIdentity]=useState({});
 const [etude,setEtude]=useState([]);
 const [experience,setExperience]=useState([]);
 const [autrecpt,setAutrecpt]=useState([]);
  const [load,setLoad]=useState(false);
  const [message, setMessage] = React.useState({
    open: false,
    color: "green",
    messageInfo: "",
  });
  const { open, color, messageInfo } = message;
  const [form,setForm]=useState(
    {
       com:""
    }
  );
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };
  
  const handleenvoie = () => {
      console.log(form);
    // if (form.email==="") {  
    //   setMessage({...message, open: true, color: 'red', messageInfo: 'Veuillez completer le champ'});
    // }
    // else if (form.id_poste==="") {
    //   setMessage({...message, open: true, color: 'red', messageInfo: 'Veuillez completer le champ'});
    // }
    // else{
    //   // navigate('/etatcivil?ref=AZERT');
      Formservice.soumettre(form,ref).then((result) => {
        if(result.status){
          setMessage({...message, open: true, color: 'green', messageInfo: "Succès"});
          setLoad(true);
          navigate('/finaly');
        }else{
          setMessage({...message, open: true, color: 'red', messageInfo: "Erreur"});
        }
      })
      .catch((err) => {
        setMessage({...message, open: true, color: 'red', messageInfo: 'erreur'});
      });
  //  }
    
  };

  useEffect(() => {
    Formservice.details(ref).then((result) => {
      if (result && result.data) {
           setIdentity(result.data);
           setForm({ ...form, com: result.data.comments });
           setEtude(result.etude);
           setExperience(result.exp);
           setAutrecpt(result.autreep);
      } else {
       
      }
    });
  }, []);
  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }
  return (
    <div style={{ display: 'grid', gridTemplateRows: 'auto 1fr auto', minHeight: '100vh',backgroundColor:"white", }}>
      <Message
        open={open}
        color={color}
        messageInfo={messageInfo}
        handleClose={handleCloseMessage}
      />
       <header style={{  height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          
        </header>
        <div style={{  padding: '20px', borderRadius: "20px", borderColor: "#0747a1", borderStyle: "solid", borderWidth: "3px", height: "auto", width: "60vw", marginLeft: "auto", marginRight: "auto", marginTop: "auto", marginBottom: "auto", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <main>
          <Grid container item xs={12}> 
             <img src={logo} className="App-logo" alt="logo" style={{ maxWidth: "10%", maxHeight: "20%" ,marginLeft:"40%" }} />
          </Grid>
            <Grid container item xs={12}  justify="center">
               <Grid container item xs={12} justify="center"> 
                  <h1 style={{ marginBottom:"10px"}}>Resumé</h1><br/>
                </Grid>
                  <TableContainer component={Paper} style={{ marginTop: '10px' ,marginBottom: '20px', width: "60vw"}}>
                                  <Table>
                                      <TableHead>
                                        {prefix!=='CI'&&
                                          <TableRow >
                                              <TableCell>Poste </TableCell>
                                              <TableCell>{identity.poste}</TableCell>
                                          </TableRow>
                                        } 
                                          <TableRow >
                                              <TableCell>Nom et Prénom(s) </TableCell>
                                              <TableCell>{identity.nom}</TableCell>
                                          </TableRow>
                                          <TableRow >
                                              <TableCell>Date de naissance </TableCell>
                                              <TableCell>{identity.ddn}</TableCell>
                                          </TableRow>
                                          <TableRow >
                                              <TableCell>Adresse </TableCell>
                                              <TableCell>{identity.adresse}</TableCell>
                                          </TableRow>
                                          <TableRow >
                                              <TableCell>Contact </TableCell>
                                              <TableCell>{identity.contact}</TableCell>
                                          </TableRow>
                                         
                                      </TableHead>
                                  </Table> 
                  </TableContainer>       
                          <TableContainer component={Paper} style={{ marginTop: '10px' ,marginBottom: '20px', width: "60vw"}}>
                                  <Table>
                                      <TableHead>
                                          <TableRow style={{backgroundColor:"gray",padding:'0px'}}>
                                              <TableCell>Année </TableCell>
                                              <TableCell>Diplôme</TableCell>
                                              <TableCell>Etablissement</TableCell>
                                              <TableCell>Filière</TableCell>
                                          </TableRow>
                                      </TableHead>
                                      <TableBody>
                                          {etude.map((data, index) => (
                                              <TableRow key={index}>
                                                  <TableCell>{data.annee}</TableCell>
                                                  <TableCell>{data.diplome}</TableCell>
                                                  <TableCell>{data.etablissement}</TableCell>
                                                  <TableCell>{data.filiere}</TableCell>
                                              </TableRow>
                                          ))}
                                      </TableBody>
                                  </Table>
                              </TableContainer>
                           <TableContainer component={Paper} style={{ marginTop: '10px' ,marginBottom: '20px', width: "60vw"}}>
                                  <Table>
                                      <TableHead>
                                          <TableRow style={{backgroundColor:"gray",padding:'0px'}}>
                                              <TableCell>Date </TableCell>
                                              <TableCell>Entreprise</TableCell>
                                              <TableCell>Domaine</TableCell>
                                              <TableCell>Sous_domaine</TableCell>
                                              <TableCell>Statut d'emploi</TableCell>
                                              <TableCell>Poste occupée</TableCell>
                                              <TableCell>Mission</TableCell>
                                              <TableCell>PJ</TableCell>
                                          </TableRow>
                                      </TableHead>
                                      <TableBody>
                                          {experience.map((data, index) => (
                                              <TableRow key={index}>
                                                  {/* <TableCell>{data.periode}</TableCell> */}
                                                  {data.date_fin !== '00/00/0000'? (
                                                            <TableCell>{data.date_debut} - {data.date_fin}</TableCell>
                                                        ) : (
                                                            <TableCell>{data.date_debut} - {data.fin_contrat}</TableCell>
                                                        )} 
                                                  <TableCell>{data.organisme_experience}</TableCell>
                                                  <TableCell>{data.domaine_experience}</TableCell>
                                                  <TableCell>{data.sous_domaine_experience}</TableCell> 
                                                  <TableCell>{data.statut}</TableCell>
                                                  <TableCell>{data.experience_mission}</TableCell>
                                                  <TableCell>{data.description_experience}</TableCell>
                                                  <TableCell>  
                                                    {data.file_experience==""?
                                                      ""
                                                     :  
                                                     <a href={`${apiurl}/uploads/attestation/${data.file_experience}`} target="_blank" rel="noopener noreferrer">PJ</a>
                                                    } 
                                                  </TableCell>
                                              </TableRow>
                                          ))}
                                      </TableBody>
                                  </Table>
                              </TableContainer>

                              <TableContainer component={Paper} style={{ marginTop: '10px' ,marginBottom: '20px', width: "60vw"}}>
                                  <Table>
                                      <TableHead>
                                          <TableRow style={{backgroundColor:"gray",padding:'0px'}}>
                                              <TableCell>Competence </TableCell>
                                              <TableCell>Type</TableCell>
                                              <TableCell>Niveau</TableCell>
                                          </TableRow>
                                      </TableHead>
                                      <TableBody>
                                           {autrecpt
                                                          .filter((data, index, self) =>
                                                              index === self.findIndex((t) => (
                                                                  t.competence === data.competence && t.type === data.type && t.niveau === data.niveau
                                                              ))
                                                          )
                                                          .map((data, index) => (  
                                                      <TableRow key={index}>
                                                          <TableCell>{data.competence}</TableCell>
                                                          <TableCell>{data.type}</TableCell>
                                                          <TableCell>{data.niveau}</TableCell>
                                                      </TableRow>
                                          ))}
                                      </TableBody>
                                  </Table>
                              </TableContainer>
                              <TableContainer component={Paper} style={{ marginTop: '10px' ,marginBottom: '20px', width: "60vw"}}>
                                  <Table>
                                      <TableHead>
                                          <TableRow >
                                              <TableCell>CV </TableCell>
                                              <TableCell><a href={`${apiurl}/uploads/cv/${identity.file_cv}`} target="_blank" rel="noopener noreferrer">PJ</a></TableCell>
                                          </TableRow>
                                          {prefix!=='CI'&&
                                             <TableRow >
                                                  <TableCell>LM </TableCell>
                                                  <TableCell><a href={`${apiurl}/uploads/lm/${identity.lm}`} target="_blank" rel="noopener noreferrer">PJ</a></TableCell>
                                              </TableRow>
                                          }  
                                          <TableRow >
                                              <TableCell>Signature </TableCell>
                                              <TableCell><a href={`${apiurl}/uploads/signature/${identity.file_diplome}`} target="_blank" rel="noopener noreferrer">PJ</a></TableCell>
                                          </TableRow>
                                          <TableRow >
                                              <TableCell>Photos </TableCell>
                                              <TableCell><a href={`${apiurl}/uploads/photo/${identity.photo}`} target="_blank" rel="noopener noreferrer">PJ</a></TableCell>
                                          </TableRow>
                                          <TableRow >
                                              <TableCell>NIF </TableCell>
                                              <TableCell><a href={`${apiurl}/uploads/nif/${identity.file_carte_fiscal}`} target="_blank" rel="noopener noreferrer">PJ</a></TableCell>
                                          </TableRow>
                                          <TableRow >
                                              <TableCell>STAT </TableCell>
                                              <TableCell><a href={`${apiurl}/uploads/stat/${identity.file_carte_stat}`} target="_blank" rel="noopener noreferrer">PJ</a></TableCell>
                                          </TableRow>
                                         
                                      </TableHead>
                                  </Table> 
                  </TableContainer>  
                              <Grid item xs={12}> 
                                     <TextField style={{ width: '100%', marginBottom: "30px" }} id="outlined-basic" size="small" label="Commentaire" variant="outlined" name="com" multiline rows={5} value={form.com} onChange={handleChange} />
                                </Grid>
            </Grid>
              <Grid item xs={12} justify="center">
                    <Button variant="contained"  color="primary" style={{ fontWeight: 600,marginLeft:"40%",marginBottom:"20px" }} onClick={handleenvoie}>VALIDER</Button>
              </Grid>
          </main>
          <footer style={{ color: '#0747a1', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            &copy; 2024 FID - FONDS D'INTERVENTION POUR LE DEVELOPPEMENT
          </footer>
        </div>
      </div>

  );
}

export default Resume;
