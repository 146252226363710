import React ,{useState,useEffect}from 'react';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {Button} from "@material-ui/core";
import logo from './logo.png';
import { useNavigate } from 'react-router-dom';
import Formservice from "./../service/Formservice";
import Message from "./Message";
import { useLocation } from 'react-router-dom';
import PGIService from "./../service/PGIService";

function Accueil() {
  const [poste, setPoste] = React.useState([]);
  const [identif, setIdentif] = React.useState({});
  const[ref,setRef]=useState("");
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get('ref');
  const [load,setLoad]=useState(false);
  const [message, setMessage] = React.useState({
    open: false,
    color: "green",
    messageInfo: "",
  });
  const { open, color, messageInfo } = message;
  const [form,setForm]=useState(
    {
        id_poste:"",
        email:"",
        notif:false
    }
  );
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  
  const handleinterne = () => {
   
    Formservice.saveacceuil(form).then((result) => {
      if(result.status){
        setRef(result.data);
        localStorage.setItem('ref', result.data);
        navigate('/etatcivil');
      }else{
      }
    })
    .catch((err) => {
      
    });
  }
  
  const handleenvoie = () => {
   
    if (form.email==="") {  
      setMessage({...message, open: true, color: 'red', messageInfo: 'Veuillez completer le champ'});
    }
    else if (form.id_poste==="") {
      setMessage({...message, open: true, color: 'red', messageInfo: 'Veuillez completer le champ'});
    }
    else{
      Formservice.saveacceuil(form).then((result) => {
        if(result.status){
          setMessage({...message, open: true, color: 'green', messageInfo: "Succès"});
          setRef(result.data);
          setLoad(true);
          //navigate('/etatcivil?ref='+result.data);
        }else{
          setMessage({...message, open: true, color: 'red', messageInfo: "Erreur"});
        }
      })
      .catch((err) => {
        setMessage({...message, open: true, color: 'red', messageInfo: 'erreur'});
      });
    }
    
  };

  useEffect(() => {

    Formservice.liste().then((result) => {
      if (result && result.data) {
           setPoste(result.data);
      } else {
       
      }
    });
    if (id!==null && id!==undefined) {
      PGIService.details(id).then((result) => {
        if (result && result.data) {
             setIdentif(result.data);
             localStorage.setItem('userName', result.data.last_name);
             localStorage.setItem('firstName', result.data.first_name);
             setForm({ ...form, email: result.data.email });
        } else {
         
        }
      });
    }
  }, []);
  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }
  return (
    <div style={{ display: 'grid', gridTemplateRows: 'auto 1fr auto', minHeight: '100vh',backgroundColor:"white", }}>
      <Message
        open={open}
        color={color}
        messageInfo={messageInfo}
        handleClose={handleCloseMessage}
      />
       <header style={{  height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          
        </header>
        <div style={{  padding: '20px', borderRadius: "20px", borderColor: "#0747a1", borderStyle: "solid", borderWidth: "3px", height: "auto", width: "60vw", marginLeft: "auto", marginRight: "auto", marginTop: "auto", marginBottom: "auto", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <main>
          <Grid container item xs={12}> 
             <img src={logo} className="App-logo" alt="logo" style={{ maxWidth: "10%", maxHeight: "20%" ,marginLeft:"40%" }} />
          </Grid>
            
          <Grid container item xs={12} justifyContent="center">
                {id == null && (
                  <>
                    <Grid item xs={12} container justifyContent="center">
                      <h1 style={{ marginBottom: "10px" }}>Soumission Dossier</h1>
                      <Grid item xs={12} container justifyContent="center">
                        <fieldset style={{ width: '70%', padding: '0 15px', border: '1px solid #ccc', borderRadius: '4px' }}>
                          <legend>Instruction</legend>
                          <label>
                            1-Veuillez choisir le poste qui vous intéresse
                          </label>
                          <br />
                          <label>
                            2-Veuillez entrer l'adresse e-mail valide
                          </label>
                          <br />
                        </fieldset>
                      </Grid>
                      <Grid item xs={12} container justifyContent="center">
                        <FormControl sx={{ m: 2, width: '70%' }} size="small">
                          <InputLabel id="demo-select-small-label">Poste</InputLabel>
                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={form.id_poste}
                            label="Poste"
                            required
                            name='id_poste'
                            onChange={handleChange}
                            style={{ height: "50px" }}
                          >
                            {poste.length > 0 && poste.map((pt, index) => (
                              <MenuItem key={index} value={pt.id_type}>
                                {pt.libelle_type}
                                {pt.description !== "" && ` - ${pt.description}`}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} container justifyContent="center">
                        <TextField
                          required
                          style={{ width: '70%', marginBottom: "20px" }}
                          InputProps={{ style: { height: '50px' } }}
                          id="outlined-basic"
                          size="small"
                          label="E-mail"
                          variant="outlined"
                          name="email"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} container justifyContent="center" alignItems="center" style={{marginBottom: "20px" }}>
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          style={{ marginRight: '8px' }}
                        />
                        <label>Me notifier si mon dossier est incomplet</label>
                      </Grid>
                      {load && (
                        <>
                          <Grid item xs={12} container justifyContent="center">
                            <label
                              required
                              style={{ width: '70%', marginBottom: "0px" }}
                            >
                              Veuillez consulter votre courriel pour le lien et suivre la soumission en ligne. Avant de continuer, veuillez rassembler ce qui est nécessaire:
                            </label>
                          </Grid>
                          <Grid item xs={12} container justifyContent="center">
                            <ul style={{ width: '70%', marginBottom: "20px" }}>
                              <li>Un diplôme disponible sous forme de PDF ou image</li>
                              <li>Une photo d'identité en PNG ou JPG</li>
                              <li>Un scan de l'attestation de travail disponible</li>
                              <li>Une lettre de motivation au format PDF</li>
                              <li>Un scan de la Carte NIF et STAT si disponible</li>
                            </ul>
                          </Grid>
                          <Grid item xs={12} container justifyContent="center">
                            <label style={{ width: '70%', marginBottom: "20px", color: "red" }}>
                              NB: Veuillez compléter toutes les informations, car elles seront utilisées pour remplir le modèle de CV du FID
                            </label>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </>
                )}
                {id !== null && (
                  <Grid container item xs={12} justifyContent="center">
                    <h1 style={{ marginBottom: "10px" }}>Mise à jour CV en ligne</h1>
                    <Grid item xs={12} container justifyContent="center">
                      <fieldset style={{ width: '90%', marginBottom: "20px", padding: '0 15px', border: '1px solid #ccc', borderRadius: '4px' }}>
                        <legend>Informations</legend>
                        <Grid container spacing={3}>
                          <Grid item xs={3}>
                            <label>Nom :</label>
                          </Grid>
                          <Grid item xs={9}>
                            <label>{identif.last_name}</label>
                          </Grid>
                          <Grid item xs={3}>
                            <label>Prénom(s) :</label>
                          </Grid>
                          <Grid item xs={9}>
                            <label>{identif.first_name}</label>
                          </Grid>
                          <Grid item xs={3}>
                            <label>Direction :</label>
                          </Grid>
                          <Grid item xs={9}>
                            <label>{identif.direction_name}</label>
                          </Grid>
                          <Grid item xs={3}>
                            <label>Service :</label>
                          </Grid>
                          <Grid item xs={9}>
                            <label>{identif.service_name}</label>
                          </Grid>
                          <Grid item xs={3}>
                            <label>Fonction :</label>
                          </Grid>
                          <Grid item xs={9}>
                            <label>{identif.function_name}</label>
                          </Grid>
                        </Grid>
                      </fieldset>
                    </Grid>
                    <Grid item xs={12} container justifyContent="center">
                      <Button variant="outlined" color="primary" style={{ fontWeight: 600, marginBottom: "20px" }} onClick={handleinterne}>
                        Continuer
                      </Button>
                    </Grid>
                  </Grid>
                )}
                {!load && id == null && (
                  <Grid item xs={12} container justifyContent="center">
                    <Button variant="contained" color="primary" style={{ fontWeight: 600, marginBottom: "20px" }} onClick={handleenvoie}>
                      ENREGISTRER
                    </Button>
                  </Grid>
                )}
              </Grid>

              
          </main>
          <footer style={{ color: '#0747a1', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            &copy; 2024 FID - FONDS D'INTERVENTION POUR LE DEVELOPPEMENT
          </footer>
        </div>
      </div>

  );
}

export default Accueil;
