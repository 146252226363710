import React, { useState ,useEffect} from 'react';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Autocomplete from '@mui/material/Autocomplete';
import {FormControl,
    Select,
    MenuItem,
    InputLabel,
    Button, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, IconButton } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import logo from './logo.png';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Message from "./Message";
import Formservice from "./../service/Formservice";
import { CreateTwoTone } from "@material-ui/icons";

function Etude() {
    const navigate = useNavigate();
    const location = useLocation();
    // const params = new URLSearchParams(location.search);
    const ref = localStorage.getItem('ref');;
    const parts = ref.split('_');
    const prefix = parts[0];
    const apiurl = process.env.REACT_APP_API_FILE;
    const [selectedYear, setSelectedYear] = useState(null);
    const [message, setMessage] = React.useState({
        open: false,
        color: "green",
        messageInfo: "",
      });
      const { open, color, messageInfo } = message;
    const [form, setForm] = useState({
        id:"",
        diplome: "",
        filiere: "",
        annee: "",
        description: "",
        etablissement: "",
        autrefiliere:""
    });

    const [tableData, setTableData] = useState([]);
    const [identif, setIdentif] = useState({});
    const years = [];
    const currentYear = new Date().getFullYear();

    for (let year = 1950; year <= currentYear; year++) {
        years.push(year);
    }

    const handleChangeval = (event) => {
        const { name, value } = event.target;
        setForm({ ...form, [name]: value });
    };

    const handleChange = (event, value) => {
        setSelectedYear(value);
        setForm({ ...form, "annee": value });
    };

    const handleSave = () => {
        const isNewRecord = form.id === "";
    
        // Vérification des champs obligatoires
        if (!isNewRecord && form.filiere === "Autres" && form.autrefiliere === "") {
            setMessage({...message, open: true, color: 'red', messageInfo: "Veuillez préciser l'autre filière"});
            return;
        }
    
        if (form.diplome === "" || form.filiere === "" || form.annee === "" || form.description === "" || form.etablissement === "") {
            setMessage({...message, open: true, color: 'red', messageInfo: 'Veuillez compléter tous les champs obligatoires'});
            return;
        }
    
        const newData = {
            diplome: form.diplome,
            filiere: form.filiere === "Autres" ? form.autrefiliere : form.filiere,
            annee: selectedYear,
            description: form.description,
            etablissement: form.etablissement,
            attestation: form.attestation
        };
    
        const formServiceCall = isNewRecord
            ? Formservice.saveetude(newData, ref)
            : Formservice.updateetude(newData, ref, form.id);
    
        formServiceCall
            .then((result) => {
                if (result.status) {
                    setTableData(result.data);
                    setMessage({...message, open: true, color: 'green', messageInfo: "Succès"});
                } else {
                    setMessage({...message, open: true, color: 'red', messageInfo: "Erreur"});
                }
            })
            .catch((err) => {
                setMessage({...message, open: true, color: 'red', messageInfo: 'Erreur'});
            })
            .finally(() => {
                setSelectedYear(null);
                setForm({
                    id: "",
                    diplome: "",
                    filiere: "",
                    annee: "",
                    description: "",
                    etablissement: "",
                    attestation: "",
                    autrefiliere: ""
                });
            });
    };
    
  

    const handleEdit = (index) => {
        let result = tableData.find(item => item.id_etude === index);
        setForm({
            id:result.id_etude,
            diplome: result.diplome,
            filiere: result.filiere,
            annee: result.annee,
            description: result.description,
            etablissement: result.etablissement,
            attestation:""
        });
        setSelectedYear(result.annee);
    }

    const handleDelete = (index) => {
        Formservice.deleteetude(index,ref).then((result) => {
            if(result.status){
              setMessage({...message, open: true, color: 'green', messageInfo: "Succès"});
              setTableData(result.data);
            }else{
              setMessage({...message, open: true, color: 'red', messageInfo: "Erreur"});
            }
          })
          .catch((err) => {
            setMessage({...message, open: true, color: 'red', messageInfo: 'erreur'});
          });
    };

    const handleenvoie = () => {
        if (tableData.length===0 ) {
            setMessage({...message, open: true, color: 'red', messageInfo: 'Veuillez compléter le champ'});
        }
        else{
           navigate('/experience');    
        }
    }

    function onFileChange(event){ 
        setForm({ ...form, "attestation": event.target.files[0] });
      }

    function handleCloseMessage() {
        setMessage({ ...message, open: false });
      }

      const handleretour = () => {
        navigate('/etatcivil');
      }

      useEffect(() => {
        Formservice.details(ref).then((result) => {
          if (result && result.data) {
               setIdentif(result.data);
               setTableData(result.etude);
          } else {
           
          }
        });
      }, []);

    return (
        <div style={{ display: 'grid', gridTemplateRows: 'auto 1fr auto', minHeight: '100vh', backgroundColor: "white" }}>
            <Message
                open={open}
                color={color}
                messageInfo={messageInfo}
                handleClose={handleCloseMessage}
            />
            <header style={{ height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img src={logo} alt='logo' style={{ maxWidth: "10%", maxHeight: "20%", marginLeft: "40%", marginBottom: "20px" }} />
            </header>
            <div style={{ padding: '20px', borderRadius: "20px", borderColor: "#0747a1", borderStyle: "solid", borderWidth: "3px", height: "auto", width: "60vw", marginLeft: "auto", marginRight: "auto", marginTop: "auto", marginBottom: "auto", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <main>
                    <Grid container item xs={12}> 
                      <img src={logo} className="App-logo" alt="logo" style={{ maxWidth: "10%", maxHeight: "20%" ,marginLeft:"40%" }} />
                    </Grid>
                    <Grid container spacing={3} justify="center">
                        <h1 style={{ marginBottom: "10px" }}>Cursus universitaire</h1>
                        <Grid container spacing={3}>
                                <Grid item xs={5} style={{ marginBottom:"10px"}} justify="center">
                                <fieldset>
                                    <legend>Identifiant</legend>
                               
                                        <label style={{textAlign:"center"}}>
                                                Réference du Dossier :{ref}
                                        </label>
                                        <br/>
                                        <label >
                                                Nom : {identif.nom}
                                        </label>
                                        <br/>
                                        {prefix!=='CI'&&
                                                <label >
                                                Poste: {identif.poste}
                                                </label>
                                        }
                                      
                                        <br/>
                                      </fieldset>
                                </Grid>
                                <Grid item xs={7} style={{ marginBottom:"20px",color:"red"}}>
                                    <fieldset>
                                        <legend>NB</legend>
                                        <label >
                                            - Tous les champs avec * sont obligatoires.
                                            </label>
                                            <br/>
                                            <label >
                                            - Le bouton Ajouter permet d'enregistrer les études.
                                            </label>
                                            
                                            <br/>
                                            <label >
                                            - Veuillez cliquer sur suivant pour passer au suivant.
                                            </label>
                                    </fieldset>
                                    </Grid>     
                                </Grid>
                        <fieldset>
                            <legend>Etudes</legend>
                            <TableContainer component={Paper} style={{ marginTop: '10px' ,marginBottom: '20px'}}>
                                  <Table>
                                      <TableHead>
                                          <TableRow style={{backgroundColor:"gray",padding:'0px'}}>
                                              <TableCell>Diplôme</TableCell>
                                              <TableCell>Filière</TableCell>
                                              <TableCell>Année</TableCell>
                                              <TableCell>Parcours</TableCell>
                                              <TableCell>Établissement</TableCell>
                                              <TableCell>Attestation/Diplôme</TableCell>
                                              <TableCell>Action</TableCell>
                                          </TableRow>
                                      </TableHead>
                                      <TableBody>
                                          {tableData.map((data, index) => (
                                              <TableRow key={index}>
                                                  <TableCell>{data.diplome}</TableCell>
                                                  <TableCell>{data.filiere}</TableCell>
                                                  <TableCell>{data.annee}</TableCell>
                                                  <TableCell>{data.description}</TableCell>
                                                  <TableCell>{data.etablissement}</TableCell>
                                                  <TableCell>{data.file_diplome==""?"":<a href={`${apiurl}/uploads/diplome/${data.file_diplome}`} target="_blank" rel="noopener noreferrer">PJ</a>}</TableCell>
                                                  <TableCell>
                                                       <IconButton aria-label="delete" color="primary" onClick={() => handleEdit(data.id_etude)}>
                                                                <CreateTwoTone />
                                                      </IconButton>
                                                      <IconButton aria-label="delete" color="secondary" onClick={() => handleDelete(data.id_etude)}>
                                                          <DeleteIcon />
                                                      </IconButton>
                                                  </TableCell>
                                              </TableRow>
                                          ))}
                                      </TableBody>
                                  </Table>
                              </TableContainer>
                              <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <label >
                                         Diplôme * :
                                    </label>
                                </Grid>
                                <Grid item xs={9}>
                                    <FormControl fullWidth variant="outlined" size="small" style={{ width: '100%', marginBottom: "15px" }}>
                                        <InputLabel id="demo-simple-select-outlined-label">
                                        Diplôme
                                        </InputLabel>
                                        <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        label="direction"
                                        value={form.diplome}
                                        name="diplome"
                                        onChange={handleChangeval}
                                        >
                                            <MenuItem value="CEPE">CEPE</MenuItem>
                                            <MenuItem value="BEPC">BEPC</MenuItem>
                                            <MenuItem value="Bacc">Bacc</MenuItem>
                                            <MenuItem value="Bacc+2-DTS">Bacc+2-DTS</MenuItem>
                                            <MenuItem value="Bacc+3-Licence">Bacc+3-Licence</MenuItem>
                                            <MenuItem value="Bacc+4-Master I">Bacc+4-Maitrise</MenuItem>
                                            <MenuItem value="Bacc+5-Master II">Bacc+5-Master II/Ingénieur</MenuItem>
                                            <MenuItem value="DEA">DEA</MenuItem>
                                            <MenuItem value="AEA">AEA</MenuItem>
                                            <MenuItem value="Doctorat">Doctorat</MenuItem>
                                        </Select>
                                    </FormControl>
                              </Grid>
                              <Grid item xs={3}>
                                    <label >
                                         Filière * :
                                    </label>
                                </Grid>
                                <Grid item xs={9}>
                                    <FormControl fullWidth variant="outlined" size="small" style={{ width: '100%', marginBottom: "15px" }}>
                                        <InputLabel id="demo-simple-select-outlined-label">
                                        Filière
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            label="direction"
                                            value={form.filiere}
                                            name="filiere"
                                            onChange={handleChangeval}
                                        >
                                            <MenuItem value="Sciences et technologies">Sciences et technologies</MenuItem>
                                            <MenuItem value="Sciences sociales et humaines">Sciences sociales et humaines</MenuItem>
                                            <MenuItem value="Arts et lettres">Arts et lettres</MenuItem>
                                            <MenuItem value="Santé et sciences de la vie">Santé et sciences de la vie</MenuItem>
                                            <MenuItem value="Commerce et gestion">Commerce et gestion</MenuItem>
                                            <MenuItem value="Environnement et développement durable">Environnement et développement durable</MenuItem>
                                            <MenuItem value="Industrie pétrolière et gazière">Industrie pétrolière et gazière</MenuItem>
                                            <MenuItem value="Santé et sciences de la vie">Santé et sciences de la vie</MenuItem>
                                            <MenuItem value="Géologie et géosciences">Géologie et géosciences</MenuItem>
                                            <MenuItem value="Elevages et agriculture">Elevages et agriculture</MenuItem>
                                            <MenuItem value="Autres">Autres</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {form.filiere=="Autres"&&
                                     <>
                                           <Grid item xs={3}>
                                                <label >
                                                Autres filière * :
                                                </label>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <TextField style={{ width: '100%', marginBottom: "15px" }} id="outlined-basic" size="small" label="Description" variant="outlined" name="autrefiliere" value={form.autrefiliere} onChange={handleChangeval} />
                                            </Grid>
                                     </>
                                }
                                <Grid item xs={3}>
                                    <label >
                                      Parcours * :
                                    </label>
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField style={{ width: '100%', marginBottom: "15px" }} id="outlined-basic" size="small" label="Parcours" variant="outlined" name="description" value={form.description} onChange={handleChangeval} />
                                </Grid>
                                <Grid item xs={3}>
                                    <label >
                                       Année * :
                                    </label>
                                </Grid>
                                <Grid item xs={9}>
                                        <Autocomplete
                                            value={selectedYear}
                                            onChange={handleChange}
                                            options={years}
                                            style={{ width: '100%', marginBottom: "15px" }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Sélectionnez une année"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                            PopupComponent={({ children }) => (
                                                <div style={{ maxHeight: 100, overflowX: 'auto', bottom: 0 }}>
                                                    {children}
                                                </div>
                                            )}
                                        />
                                 </Grid>
                                <Grid item xs={3}>
                                    <label >
                                        Etablissement * :
                                    </label>
                                </Grid>
                                <Grid item xs={9}>
                                <TextField style={{ width: '100%', marginBottom: "30px" }} id="outlined-basic" size="small" label="Votre établissement sans abreviation" variant="outlined" name="etablissement" value={form.etablissement} onChange={handleChangeval} />
                                </Grid>
                                <Grid item xs={3}>
                                    <label >
                                        Attestation/Diplôme:
                                    </label>
                                  </Grid>
                                  <Grid item xs={9}>
                                      <input type="file" name="attestation" onChange={onFileChange} />
                                  </Grid>
                             </Grid>
                             {form.id===""?
                               <Button variant="outlined" color="primary" style={{ fontWeight: 400, marginLeft: "80%", marginBottom: "10px" }} onClick={handleSave}>Ajouter</Button>
                               : <Button variant="outlined" color="primary" style={{ fontWeight: 400, marginLeft: "80%", marginBottom: "10px" }} onClick={handleSave}>Modifier</Button> 
                             }
                           
                        </fieldset>
                        
                    </Grid>
                    <Grid container xs={12} style={{marginTop:"40px"}} >
                        <Grid item xs={6} >
                              
                          </Grid>
                        <Grid item xs={2} >
                              <Button variant="outlined"  color="primary" style={{ fontWeight: 600,marginLeft:"40%",marginBottom:"20px" }} onClick={handleretour}>Retour</Button>
                          </Grid>
                          <Grid item xs={4} >
                            <Button variant="outlined"  color="primary" style={{ fontWeight: 600,marginLeft:"40%",marginBottom:"20px" }} onClick={handleenvoie}>Suivant</Button>
                          </Grid>
                          
                    </Grid>
                    
                </main>
                <footer style={{ color: '#0747a1', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    &copy; 2024 FID - FONDS D'INTERVENTION POUR LE DEVELOPPEMENT
                </footer>
            </div>
        </div>
    );
}

export default Etude;
