import React ,{useState,useEffect}from 'react';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";
import logo from './logo.png';
import { useNavigate } from 'react-router-dom';
import Formservice from "./../service/Formservice";
import { useLocation } from 'react-router-dom';
import Message from "./Message";
import Phone from "./Phone";
import Alert from "@material-ui/lab/Alert";
import {DeleteOutlineRounded} from "@material-ui/icons";
import AddIcon from '@mui/icons-material/Add';

function Etatcivil() {
  const navigate = useNavigate();
  const location = useLocation();
  // const params = new URLSearchParams(location.search);
  const ref = localStorage.getItem('ref');
  const parts = ref.split('_');
  const prefix = parts[0];
  const [message, setMessage] = React.useState({
    open: false,
    color: "green",
    messageInfo: "",
  });
  const { open, color, messageInfo } = message;
  const [identif,setIdentif]=useState([]);
  const [form,setForm]=useState(
    {
      id:"",
      ref:ref,
      nom:"",
      prenom:"",
      ddn:"",
      adres:"",
      tel1:"",
      tel2:"",
      cin:""
    }
  );
  const [number, setNumber] = useState(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ' ', '']);
  const [compt, setCompt] = useState(false);
  const [isPhoneNumberEmpty, setIsPhoneNumberEmpty] = useState(false);
  const [isPhoneNumberLengthNotValid, setPhoneNumberLengthNotValid] = useState(false);


  function setCINvalue(value) {
    if (value) {
      const cin = value.replace(/[^\d]/g, "");
      const number = cin.length;
      if (number < 4) {
        return cin;
      }
      if (number < 7) {
        return `${cin.slice(0, 3)} ${cin.slice(3, 6)}`;
      }
      if (number < 10) {
        return `${cin.slice(0, 3)} ${cin.slice(3, 6)} ${cin.slice(6, 9)}`;
      }

      return `${cin.slice(0, 3)} ${cin.slice(3, 6)} ${cin.slice(
        6,
        9
      )} ${cin.slice(9, 12)}`;
    } else {
      return "";
    }
  }
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name=="cin") {
      const newval = setCINvalue(value);
      setForm({ ...form, [name]: newval });
    } else {
      setForm({ ...form, [name]: value });
    }
    
  };



 

  const handleChange1=(event)=>{
    const { name, value } = event.target;
    var i = 0;
      var a = 0;
      for (let index = 0; index < value.length; index++) {
        for (let indexs = 0; indexs < number.length; indexs++) {
          if (number[indexs] == value[index]) {
            i++;
          }
        }
      }
      if (i != value.length) {
        setCompt(true);
      }
      else {
        setCompt(false);
        setForm({ ...form, [name]: value });
       
      }
  }

  function handleFocusPhoneNumber() {
    setIsPhoneNumberEmpty(false);
    setPhoneNumberLengthNotValid(false);
  }

  const handleenvoie = () => {
   
      if (form.nom==="" || form.prenom==="" || form.adres==="" || form.ddn==="" || form.tel1==="") {   
        setMessage({...message, open: true, color: 'red', messageInfo: 'Veuillez compléter le champ'});
      }
      else{

        if (form.id !== "") {
          Formservice.updateidentity(form)
            .then((result) => {
              handleResult(result);
            })
            .catch((err) => {
              setMessage({...message, open: true, color: 'red', messageInfo: 'Erreur'});
            });
        } else {
          Formservice.saveidentity(form)
            .then((result) => {
              handleResult(result);
            })
            .catch((err) => {
              setMessage({...message, open: true, color: 'red', messageInfo: 'Erreur'});
            });
        }
        
        
    }
  };
  function handleResult(result) {
    if (result.status) {
      setMessage({...message, open: true, color: 'green', messageInfo: "Succès"});
      setTimeout(() => { navigate('/etude'); }, 4000);
    } else {
      setMessage({...message, open: true, color: 'red', messageInfo: "Erreur"});
    }
  }
  

  useEffect(() => {
    Formservice.details(ref).then((result) => {
      if (result && result.data) {
           setIdentif(result.data);
           setForm({
            id:result.data.id_identite||'',
            ref: ref,
            nom: result.data.nom_identite || '', 
            prenom: result.data.prenom_identite || '',
            ddn: result.data.ddn || '',
            adres: result.data.adresse || '',
            tel1: result.data.contact || '',
            cin: result.data.cin || ''
          });
      } else {
       
      }
    });
    if (prefix==="CI") {
      const storedName = localStorage.getItem('userName');
      const storedFirst = localStorage.getItem('firstName');
      if (storedFirst && storedName) {
        setForm({ ...form, nom: storedName,prenom: storedFirst });
      }
    }

  }, []);

  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }

  return (
    <div style={{ display: 'grid', gridTemplateRows: 'auto 1fr auto', minHeight: '100vh',backgroundColor:"white", }}>
      <Message
        open={open}
        color={color}
        messageInfo={messageInfo}
        handleClose={handleCloseMessage}
      />
        <header style={{  height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          
        </header>
        <div style={{  padding: '20px', borderRadius: "20px", borderColor: "#0747a1", borderStyle: "solid", borderWidth: "3px", height: "auto", width: "60vw", marginLeft: "auto", marginRight: "auto", marginTop: "auto", marginBottom: "auto", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <main>
          <Grid container item xs={12}> 
             <img src={logo} className="App-logo" alt="logo" style={{ maxWidth: "10%", maxHeight: "20%" ,marginLeft:"40%" }} />
          </Grid>
            <Grid container item xs={12}  justify="center">
                 {prefix==='CI'&&
                     <h1 style={{ marginBottom:"10px"}}>Mise à jour CV</h1>
                 }
                 {prefix!=='CI'&&
                   <h1 style={{ marginBottom:"10px"}}>Soumission Dossier</h1>
                 }
                 <Grid item xs={12} style={{ marginBottom:"20px"}} justify="center">
                       <label >
                             Réference du Dossier :{ref}
                        </label>
                 </Grid>
                 <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <label >
                             Nom * :
                        </label>
                    </Grid>
                    <Grid item xs={9}>
                       <TextField required style={{ width: '100%' ,marginBottom:"15px"}} value={form.nom} InputProps={{ style: { height: '50px' } }} id="outlined-basic" size="small" label="Votre Nom" variant="outlined" name="nom" onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={3}>
                        <label >
                           Prénom(s) * :
                        </label>
                    </Grid>
                    <Grid item xs={9}>
                          <TextField   style={{ width: '100%' ,marginBottom:"15px"}}  value={form.prenom} InputProps={{ style: { height: '50px' } }} id="outlined-basic" size="small" label="Votre Prénom(s)" variant="outlined" name="prenom" onChange={handleChange}/> 
                    </Grid>
                    <Grid item xs={3}>
                        <label >
                          Date de naissance * :
                        </label>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            fullWidth
                            style={{ width: '100%', marginBottom: '15px' }}
                            id="outlined-basic"
                            size="small"
                            variant="outlined"
                            type="date"
                            required
                            name="ddn"
                            value={form.ddn}
                            onChange={handleChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                      </Grid>
                      <Grid item xs={3}>
                        <label >
                          CIN * :
                        </label>
                    </Grid>
                    <Grid item xs={9}>
                          <TextField
                              fullWidth
                              id="outlined-basic"
                              size="small"
                              label="CIN"
                              variant="outlined"
                              onChange={handleChange}
                              inputProps={{ maxLength: 18 }}
                              value={form.cin}
                              name="cin"
                            />
                      </Grid>
                    <Grid item xs={3}>
                        <label >
                            Adresse* :
                        </label>
                    </Grid>
                    <Grid item xs={9}>
                      <TextField  required style={{ width: '100%' ,marginBottom:"15px"}} value={form.adres} InputProps={{ style: { height: '50px' } }} id="outlined-basic" size="small" label="Votre adresse" variant="outlined" name="adres" onChange={handleChange} />
                    </Grid>
                    <Grid item xs={3}>
                        <label >
                             Numéro de télephone* :
                        </label>
                    </Grid>
                    {/* <TextField required style={{ width: '100%' ,marginBottom:"15px"}} InputProps={{ style: { height: '50px' } }} placeholder='0340000000' id="outlined-basic" value={form.tel1}  size="small" label="Votre numéro de télephone" variant="outlined" name="tel1" onChange={handleChange} />  */}
                    <Grid item xs={9}>
                          
                          <Phone x1={3} x2={8} name="tel1" value={form.tel1} onChange={handleChange1} onFocus={handleFocusPhoneNumber} />
                          {compt && <><Alert severity="error">Le numero de téléphone ne contient pas de lettres</Alert></>}
                          {isPhoneNumberEmpty && <><Alert severity="error">Ce champ est obligatoire</Alert></>}
                         {isPhoneNumberLengthNotValid && <><Alert severity="error">Veuillez vérifier votre numéro de téléphone. La longueur n'est pas valide</Alert></>}
                    </Grid>

                             
                    {/* <Grid item xs={3}>
                        <label >
                        Autre numéro de télephones :
                        </label>
                    </Grid>
                    <Grid item xs={9}>
                            <TextField style={{ width: '100%' ,marginBottom:"15px"}} InputProps={{ style: { height: '50px' } }}  placeholder='0340000000' id="outlined-basic" value={form.tel2} size="small" label="Autre numéro de télephone" variant="outlined" name="tel2" onChange={handleChange}/> 
                     </Grid> */}
                    </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginBottom:"20px",color:"red"}}>
                       <fieldset>
                          <legend>NB</legend>
                          <label >
                              Tous les champs avec * sont obligatoires. Veuillez les compléter avant de cliquer sur enregistrer.
                            </label>
                       </fieldset>
                       
                 </Grid>
                 <Grid container xs={12} style={{marginTop:"40px"}} >
                        <Grid item xs={6} >
                              
                          </Grid>
                        <Grid item xs={2} >
                             
                          </Grid>
                          <Grid item xs={4} >
                            <Button variant="outlined"  color="primary" style={{ fontWeight: 600,marginLeft:"40%",marginBottom:"20px" }} onClick={handleenvoie}>Suivant</Button>
                          </Grid>
                          
                    </Grid>
          </main>
          <footer style={{ color: '#0747a1', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            &copy; 2024 FID - FONDS D'INTERVENTION POUR LE DEVELOPPEMENT
          </footer>
        </div>
      </div>

  );
}

export default Etatcivil;
