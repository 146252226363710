import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const apiurl2=process.env.REACT_APP_API_URL2;

const Formservice = {
  async liste() {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`, 
      'Content-Type': 'application/json', 
    };

    try {
      const result = await axios.get(`${apiUrl}/index`, { headers });
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      } else {
        throw error; 
      }
    }
  },
  async domaine() {
    try {
      const result = await axios.get(`${apiUrl}/domaine`);
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      } else {
        throw error; 
      }
    }
  },
  async details(ref) {
    try {
      const result = await axios.get(`${apiUrl}/details/`+ref);
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      } else {
        throw error; 
      }
    }
  },
  async sousdomaine(id) {
    try {
      const result = await axios.get(`${apiUrl}/sousdomaine/`+id);
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      } else {
        throw error; 
      }
    }
  },
  async saveacceuil(formData) {
    try {
      const result = await axios.post(`${apiUrl}/acceuil`, formData);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async updateidentity(formData) {
    try {
      const result = await axios.put(`${apiUrl}/identity`, formData);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async saveidentity(formData) {
    try {
      const result = await axios.post(`${apiUrl}/identity`, formData);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async competence(formData,ref) {
    try {
      const result = await axios.post(`${apiUrl}/competence/`+ref, formData);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  
  async deletecompetence(id,ref) {
    try {
      const result = await axios.get(`${apiUrl}/competencedelete/`+id+`/`+ref);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async deleteetude(id,ref) {
    try {
      const result = await axios.get(`${apiUrl}/etudedelete/`+id+`/`+ref);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async saveetude(formData,ref) {
    const formDataToSend = new FormData();
    Object.keys(formData).forEach(key => {
      formDataToSend.append(key, formData[key]);
    });
    formDataToSend.append('ref', ref)
    try {
        const response = await axios.post(`${apiUrl}/etude`, formDataToSend, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          
        });

        if (response) {
          return response.data;
        }
      } catch (error) {
        if (error.response) {
          return error.response.data;
       }
    }
  },
  async updateetude(formData,ref,id) {
    const formDataToSend = new FormData();
    Object.keys(formData).forEach(key => {
      formDataToSend.append(key, formData[key]);
    });
    formDataToSend.append('ref', ref)
    try {
        const response = await axios.post(`${apiUrl}/updateetude/`+id, formDataToSend, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          
        });

        if (response) {
          return response.data;
        }
      } catch (error) {
        if (error.response) {
          return error.response.data;
       }
    }
  },
  async deleteexperience(id,ref) {
    try {
      const result = await axios.get(`${apiUrl}/experiencedelete/`+id+`/`+ref);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async updateexperience(formData,ref,reference,id) {
    const formDataToSend = new FormData();
    Object.keys(formData).forEach(key => {
      if (key=="domaine") {
        formDataToSend.append(key, formData[key].name);
      }
      else if(key=="sous_domaine")
      {
        formDataToSend.append(key, formData[key].name);
      }
      else{
        formDataToSend.append(key, formData[key]);
      }
    });
    formDataToSend.append('ref', ref);
    Object.keys(reference).forEach(key => {
      formDataToSend.append(key, reference[key]);
    });
    try {
      const response = await axios.post(`${apiUrl}/updateexperience/`+id, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        
      });
      if (response) {
        return response.data;
      }
    } catch (error) {
    }
  },
  async saveexperience(formData,ref,reference) {
    const formDataToSend = new FormData();
    Object.keys(formData).forEach(key => {
      if (key=="domaine") {
        formDataToSend.append(key, formData[key].name);
      }
      else if(key=="sous_domaine")
      {
        formDataToSend.append(key, formData[key].name);
      }
      else{
        formDataToSend.append(key, formData[key]);
      }
    });
    formDataToSend.append('ref', ref);
    Object.keys(reference).forEach(key => {
      formDataToSend.append(key, reference[key]);
    });
    try {
      const response = await axios.post(`${apiUrl}/experience`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        
      });
      if (response) {
        return response.data;
      }
    } catch (error) {
    }
  },
  async saveautrecompetence(rows,informatique,languages,ref) {
    try {
      const formData = new FormData();
      formData.append('rows', JSON.stringify(rows)); 
      formData.append('informatique', JSON.stringify(informatique)); 
      formData.append('languages', JSON.stringify(languages));
      formData.append('ref', ref);
    
      const response = await axios.post(`${apiUrl}/autrecompetence`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    
      if (response) {
        return response.data;
      }
    } catch (error) {
    }
  },
  async savefile(formData,ref) {
    const formDataToSend = new FormData();
    Object.keys(formData).forEach(key => {
      formDataToSend.append(key, formData[key]);
    });
    formDataToSend.append('ref', ref);
    try {
      const response = await axios.post(`${apiUrl}/file`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        
      });
      if (response) {
        return response.data;
      }
    } catch (error) {
    }
  },
  async soumettre(formData,ref) {
    try {
      const result = await axios.post(`${apiUrl}/finaly/`+ref, formData);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async identifiant(ref) {
    try {
      const result = await axios.get(`${apiUrl}/identif/`+ref);
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      } else {
        throw error; 
      }
    }
  },
  async getcompetence(ref) {
    try {
      const result = await axios.get(`${apiUrl}/comptence1/`+ref);
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      } else {
        throw error; 
      }
    }
  },
  async getformation(ref) {
    try {
      const result = await axios.get(`${apiUrl}/formation1/`+ref);
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      } else {
        throw error; 
      }
    }
  },
  async description(ref,desc) {
    try {
      const result = await axios.post(`${apiUrl}/adddesc/`+ref,desc);
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      } else {
        throw error; 
      }
    }
  },

  async acceuil() {
    try {
      const result = await axios.post(`${apiurl2}/authentication/login`,{
        email:"sidonie.rasoaharinirina@fid.mg",
        isChecked: false,
        password: "SIdonie123."
      });
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      } else {
        throw error; 
      }
    }
  },
  async deleteautrecompetence(id,ref) {
    try {
      const result = await axios.get(`${apiUrl}/autrecompetencedelete/`+id+`/`+ref);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
}

export default Formservice;
